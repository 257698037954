import TopBar from '../../../components/TopBar';
export default {
  name: 'AboutUs',
  components: {
    TopBar: TopBar
  },
  data: function data() {
    return {
      top_title: '关于我们',
      btns: [{
        text: '',
        functionName: ''
      }]
    };
  },
  methods: {}
};