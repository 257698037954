<template>
  <div class='container' style='padding-bottom: 0;'>
    <TopBar :top_title='top_title'></TopBar>
    <div class='container_'>
      <div class='mycv_bannar'>
        <div class='mycv_bannar_'>
          <van-swipe class='my-swipe' :autoplay='3000' indicator-color='white'>
            <van-swipe-item v-for='(item,index) of imgs' :key='index'>
              <div class='my-swipe-div'>
                <van-image width='100%' height='100%' fit='cover' :src='item.img'/>
              </div>
            </van-swipe-item>
          </van-swipe>
        </div>
      </div>
      <div class='cvList'>
        <div class='cv' v-for='(item,index) of resumes' :key='index' @click='showDialog(item)'>
          <div class='cv_'>
            <div class='cv_name'>{{ item.resume_name }}
              <div class='cv_status'>
                <div class='cv_status_' :class="'cv_status_' + item.status">{{ status[item.status - 1] }}</div>
              </div>
            </div>
            <div class='cv_name_btn' @click.stop='editResume(item)'></div>
          </div>
          <div class='cv_'>
            <div class='cv_intention'>求职意向：{{ item.resume_data ? item.resume_data : '未填写' }}</div>
            <div class='cv_time'>{{ item.create_time }}</div>
          </div>
        </div>
      </div>
    </div>
    <van-popup round v-model:show='show' position='bottom' :style="{ height: '100px'}">
      <div style='height: 100%;display: flex;justify-content: space-around;align-items: center'>
<!--        <div class='my_btn' @click='toPreview'>-->
<!--          <div class='my_btn_icon icon_see'></div>-->
<!--          <div class='my_btn_text'>预览</div>-->
<!--        </div>-->
<!--        <div class='my_btn' @click='toDownLoad'>-->
<!--          <div class='my_btn_icon icon_download'></div>-->
<!--          <div class='my_btn_text'>下载</div>-->
<!--        </div>-->
        <div class='my_btn' @click='deleteResume'>
          <div class='my_btn_icon icon_delete'></div>
          <div class='my_btn_text'>删除</div>
        </div>
      </div>
    </van-popup>
    <van-popup round v-model:show='show2' position='bottom' :style="{ height: '80px'}">
      <div style='height: 100%;display: flex;justify-content: center;align-items: center;flex-direction: column'>
        <div class='my_btn2' @click='toDiagnosis' v-if='choseStatus === 1'>
          <div class='my_btn_text' @click="optimizationResume">AI诊断</div>
        </div>
        <div class='my_btn2' @click='ResumeScore' v-if='choseStatus === 2'>
          <div class='my_btn_text'>查看诊断结果</div>
        </div>
        <div class='my_btn2' @click='getDetail' v-if='choseStatus > 2'>
          <div class='my_btn_text'>编辑</div>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { Dialog, Toast } from 'vant'
import TopBar2 from '../../components/TopBar2'
import { getAllResume, getSingleResume, deleteResume, optimizationResume, diagnosisResume } from '../../api/MyResume'
import store from '../../store'

export default {
  name: 'index',
  components: {
    TopBar: TopBar2
  },
  data () {
    return {
      top_title: '我的简历',
      resumes: [],
      show: false,
      show2: false,
      choseStatus: 0,
      status: ['待诊断', '已完成', '编辑中', '完成'],
      choseResume: '',
      imgs: [
        {
          img: require('../../assets/images/Group_24.png')
        },
        {
          img: require('../../assets/images/bannar2.png')
        },
        {
          img: require('../../assets/images/bannar.png')
        }
      ],
    }
  },
  mounted () {
    this.getAllResume()
  },
  methods: {
    toPreview () {
      let data = this.choseResume
      if (data.template_name) {
        this.$router.push({
          path: 'resumepreview',
          query: {
            id: data.id,
            name: data.resume_name,
            status: data.status,
            template_name: data.template_name,
          }
        })
      } else {
        Toast.fail('请先选择模板')
      }
    },
    toDownLoad () {
      let data = this.choseResume
      if (data.status == '4') {
        this.$router.push({
          path: 'resume_download',
          query: {
            id: data.id,
            name: data.resume_name,
            status: data.status,
            template_name: data.template_name,
          }
        })
      } else {
        Toast.fail('请先完成简历')
      }
    },
    deleteResume () {
      let that = this
      let name = this.choseResume.resume_name
      let id = this.choseResume.id
      Dialog.confirm({
        title: '提示：',
        message: '是否永久删除' + name + '简历?'
      }).then(() => {
        deleteResume(id).then((res) => {
          console.log(res)
          that.show = false
          that.getAllResume()
          Toast.success('删除成功！')
        }).catch(() => {
          Toast.fail('删除失败！')
        })
      }).catch(() => {
        // on cancel
      })
    },
    parseResumeData (data) {
      return new Promise((resolve, reject) => {
        let obj = JSON.parse(data)
        if (obj) {
          resolve(obj)
        } else {
          reject()
        }
      })
    },
    showDialog (data) {
      // if (data.status === 2){
      // this.show2 = false
      // }else{
        this.show2 = true
        console.log('choose == ', data)
        this.choseResume = data
        this.choseStatus = data.status
      // }
    },
    editResume (data) {
      this.show = true
      this.choseResume = data
      this.choseStatus = data.status
    },
    getAllResume () {
      // let id = store.state.userInfo.id
      let id = localStorage.getItem('id');
      console.log(store.state.userInfo)
      getAllResume(id).then((res) => {
        for (let item of res.data) {
          this.parseResumeData(item.resume_data).then((res) => {
            for (let item_ of res) {
              if (item_.name === '求职意向') {
                item.intention = item_.content[0]
              }
            }
          }).catch(() => {
            // if (item.status === 1) {
            //   let i = this.resumes.findIndex(d => d.id === item.id)
            //   this.resumes[i].status = 2
            // }
          })
        }
        this.resumes = res.data
        console.log(res.data)
      })
    },
    getDetail () {
      let data = this.choseResume
      if (data.status > 2) {
        getSingleResume(data.id).then((res) => {
          let resData = JSON.parse(res.data.resume_data)

          if (res.data.template_name === undefined) {
            res.data.template_name = ''
          }
          this.$store.commit('getResumeInfo', resData)
          this.$router.push({
            path: 'editresume',
            query: {
              id: data.id,
              name: data.resume_name,
              status: res.data.status,
              template_name: res.data.template_name,
            }
          })
        })
      } else {
        let str = `简历${this.status[data.status]}`
        Toast.fail(str)
      }
    },
    optimizationResume () {
      let data = this.choseResume
      diagnosisResume(data.id)
      this.show2 = false
      this.getAllResume()
    },
    toDiagnosis () {
      store.commit('setFileId', this.choseResume.id)
      this.$router.push({
        path: '/resume_optimize',
        query: {
          file_id: this.choseResume.id,
          file_name: this.choseResume.resume_name,
          file_status:'al_upload',
          active: 2
        }
      })
    },
    ResumeScore () {
      const obj = JSON.parse(this.choseResume.diagnosis_result)
      store.commit('setFileId', this.choseResume.id)
      store.commit('setSuggestion', obj)
      this.$router.push('/resume_score')
    }
  }
}
</script>

<style scoped>
.cv_status_1 {
  background-color: #F2F2F2;
  color: #828282;
}

.cv_status_3 {
  background-color: #D9E8FC;
  color: #0164FA;
}

.cv_status_2 {
  background-color: #FFE9DB;
  color: #FF7E2B;
}

.cv_status_4 {
  background-color: #D0FAF6;
  color: #00D0C0;
}

.my_btn2 .my_btn_text {
  line-height: 50px;
}

.my_btn2 {
  width: 100%;
  height: 50px;
  border-bottom: 1px solid #F2F2F2;
}

.my_btn2:last-child {
  border-bottom: none;
}

.icon_delete {
  background-image: url(../../assets/images/icon/delete.png);
  -webkit-background-size: 100% 100%;
  background-size: 100% 100%;
}

.icon_see {
  background-image: url(../../assets/images/icon/see.png);
  -webkit-background-size: 100% 100%;
  background-size: 100% 100%;
}

.icon_download {
  background-image: url(../../assets/images/icon/download.png);
  -webkit-background-size: 100% 100%;
  background-size: 100% 100%;
}

.my_btn_text {
  text-align: center;
  font-size: 15px;
}

.my_btn_icon {
  width: 25px;
  height: 25px;
  margin-bottom: 8px;
}

.my_btn {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.my-swipe-div img {
  width: 100%;
  height: 100%;
}

.my-swipe-div {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.my-swipe {
  height: 100%;
}

.cv_time {
  color: #979797;
}

.cv_intention {
  color: #4F4F4F;
}

.cv_name_btn {
  width: 24px;
  height: 24px;
  background-image: url(../../assets/images/icon/more.png);
  -webkit-background-size: 100% 100%;
  background-size: 100% 100%;
}

.cv_name {
  font-size: 16px;
  color: #4F4F4F;
  position: relative;
}

.cv_status {
  position: absolute;
  right: -65px;
  top: -10px;
  width: 60px;
  height: 24px;
  font-size: 10px;
  display: flex;
  align-items: center;
}

.cv_status_ {
  padding: 2px 5px;
  border-radius: 5px;
  text-align: center;
}

.cv {
  height: 88px;
  width: 100%;
  padding: 20px 15px;
  background-color: #fff;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 10px;
}

.cv_ {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.cvList {
  width: 100%;
  height: calc(100% - 100px);
  overflow: scroll;
}

.mycv_bannar_ {
  position: absolute;
  bottom: 0;
  height: 144px;
  width: 100%;
  border-radius: 8px;
  overflow: hidden;
}

.mycv_bannar {
  width: 100%;
  height: 72px;
  position: relative;
  margin-bottom: 15px;
}

.container {
  height: 100%;
}

.container_ {
  margin-top: 80px;
  height: calc(100% - 180px);
  background-color: #F2F2F2;
  border-radius: 20px 20px 0 0;
  padding: 0 15px;
}
</style>
