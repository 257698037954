import request from './../util/request.js';
export function uploadFile(data) {
  return request({
    url: '/file/upload',
    data: data,
    method: 'post'
  });
}
export function htmltofile(data) {
  return request({
    url: 'resume/htmltofile',
    data: data,
    method: 'post'
  });
}
export function userFeedback(data) {
  return request({
    url: '/user/userfeedback',
    data: data,
    method: 'post'
  });
}