import "core-js/modules/es.function.name";
export default {
  props: {
    data: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  data: function data() {
    return {
      name: 'resume_optimize'
    };
  },
  watch: {
    $route: function $route() {
      this.name = this.$route.name;
    }
  },
  mounted: function mounted() {
    this.name = this.$route.name;
  },
  methods: {
    goto: function goto(data) {
      this.$router.push({
        path: data.to.name
      });
    }
  }
};