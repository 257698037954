<template>
  <div class='login_container'>
    <!--    <MyLoading :flag='flag'></MyLoading>-->
    <div class='logo'></div>
    <div class='login_btn' @click='goTo'>微信登录</div>
<!--    <div class='login_btn' @click='toIndex'>微信登录</div>-->


  </div>
</template>

<script>
// import MyLoading from '../../components/Loading'

import axios from 'axios'
import * as url from 'url'
import { wechatLogin } from 'api/MyResume'
import { Notify } from 'vant'

export default {
  name: 'Login',
  data () {
    return {
      // flag:true
      time: 0,
      code: '',
      access_token:'',
      refresh_token:''
    }
  },
  mounted () {},
  watch: {
    code(newMessage, oldMessage) {
      this.goTo()
    }
  },
  methods: {
    goTo () {
      let isWXBrowser = this.isWXBrowser()
      if (isWXBrowser) {
        this.code = this.getUrlCode('code')
        if (this.code) {
          //后台登录
          this.wxLogin(this.code)
        } else {
          //微信授权
          this.getWeChatCode()
        }
      }else{
        Notify("请使用微信内置浏览器!!")
      }
    },
    //判断是否是微信内置的浏览器
    isWXBrowser () {
      return (
        String(navigator.userAgent.toLowerCase().match(/MicroMessenger/i)) ===
        'micromessenger'
      )
    },
    //截取地址栏code
    getUrlCode (name) {
      return (
        decodeURIComponent(
          (new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(
            location.href
          ) || [, ''])[1].replace(/\+/g, '%20')
        ) || null
      )
    },
    getWeChatCode () {
      // let local = 'http://files.haijigis.com:11117/UJL/#/resume_optimize' //获取当前页面地址作为回调地址
      let local = 'http://m.haijigis.com/#/resume_optimize' //获取当前页面地址作为回调地址
      // let appid = 'wwa5832fb6f13a7f2f'
      let appid = 'wxbab7eb582a234f2a'

      //   encodeURIComponent(local.split('#')[0]) +

      window.location.href =
        'https://open.weixin.qq.com/connect/oauth2/authorize?appid=' +
        appid +
        '&redirect_uri=' +
        encodeURIComponent(local) +
        '&response_type=code&scope=snsapi_userinfo&state=1#wechat_redirect'
    },
    async wxLogin (code) {
      wechatLogin(code).then(resp => {
        if (resp.status === 'success'){
          localStorage.setItem('access_token', resp.data.access_token);
          localStorage.setItem('refresh_token', resp.data.refresh_token);
          localStorage.setItem('id', resp.data.id);
        }
        this.$store.commit('getUserInfo', resp.data)
        this.access_token = localStorage.getItem('access_token');
        this.refresh_token = localStorage.getItem('refresh_token');
        if (this.access_token && this.refresh_token) {
          this.$router.push({ path: '/resume_optimize' })
        }
      })
    },
    toIndex(){
      localStorage.setItem('access_token', '71_zaho0F513UJRs8CndEZtV0tU8PE5Q-6yIYIralFGJvZnTEvWSrN0wlq_AX_xHBe36e5Itz_DfmnSIyfFjZ8fG6tCPaWiqGFpSt9IfrYLwiI');
      localStorage.setItem('refresh_token', '71_zaho0F513UJRs8CndEZtV0tU8PE5Q-6yIYIralFGJvZnTEvWSrN0wlq_AX_xHBe36e5Itz_DfmnSIyfFjZ8fG6tCPaWiqGFpSt9IfrYLwiI');
      localStorage.setItem('id', '0d0eb9fe-ae55-43bb-b600-8e8ab51f8ec5');
      this.$router.push({ path: '/resume_optimize' })
    }
  },

}
</script>

<style scoped>
.login_btn {
  width: 228px;
  height: 41px;
  line-height: 41px;
  background-color: #0164FA;
  font-size: 14px;
  color: #FFFFFF;
  text-align: center;
  border-radius: 41px;
  position: fixed;
  left: 50%;
  bottom: 34%;
  transform: translate(-50%, 0);
}

.logo {
  width: 70px;
  height: 125px;
  background-image: url(../../assets/images/logo.png);
  -webkit-background-size: 100% 100%;
  background-size: 100% 100%;
  line-height: 150px;
  margin-bottom: 50px;
  position: fixed;
  left: 50%;
  top: 22%;
  transform: translate(-50%, 0);
}

.login_container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: scroll;
}
</style>
