<template>
  <div class='container' style='padding-bottom: 0;'>
    <TopBar :top_title='top_title' :btns='btns' :hiddenNotice='true'></TopBar>
    <div class='container_'>
      <div class="order_list">
        <div class="order_block" v-for='(item,index) of this.orders' :key='item.out_trade_no' @click="order_result(item)">
          <div class="title">
            <div class="title_word">微信支付</div>
            <div class="title_word">￥{{ item.total }}</div>
          </div>
          <div class="order_con" v-if="item.pay_type === 'pointCards'">订单名称：{{ item.points }}点数</div>
          <div class="order_con" v-if="item.pay_type === 'pointCards'">订单类型：点数增加</div>
          <div class="order_con" v-if="item.pay_type === 'package'">订单名称：{{ item.days }}天VIP</div>
          <div class="order_con" v-if="item.pay_type === 'package'">订单类型：会员时长</div>
          <div class="order_con">订单编号：{{ item.out_trade_no }}</div>
          <div class="order_con" v-if="item.trade_state === 'SUCCESS'">支付时间：{{ item.success_time }}</div>
          <div class="order_con" v-else>订单未支付</div>
        </div>

        <div class="footer">
          ————没有更多了————
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TopBar from '../../../components/TopBar'
import { getUserOrder } from 'api/MyResume'

export default {
  name: 'AboutUs',
  components: {
    TopBar: TopBar
  },
  data () {
    return {
      top_title: '我的订单',
      btns: [{
        text: '',
        functionName: ''
      }],
      orders:[]
    }
  },
  methods: {
    order_result (data) {
      this.$router.push({path:'/order_result',query: data})
    },
    getMyOrder(){
      getUserOrder(localStorage.getItem('id')).then(value => {
        this.orders = value.data
      })
    }
  },
  mounted () {
    this.getMyOrder()
  }
}
</script>

<style scoped>
.order_list {
  height: calc(100% - 100px);
}

.footer {
  color: rgba(224, 224, 224, 1);
  margin-top: 10px;
  width: 100%;
  text-align: center;
}

.order_con {
  line-height: 26px;
  font-family: Inter;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0px;
  color: rgba(130, 130, 130, 1);
}

.title {
  display: flex;
  justify-content: space-between;

  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0px;
  margin-bottom: 10px;
}

.order_block {
  padding: 15px;
  width: 100%;
  height: 160px;
  border-radius: 10px;
  background-color: #fff;
  margin-bottom: 10px;
}

.container_ {
  background-color: rgba(248, 248, 248, 1);
  padding: 15px;
  padding-bottom: 80px;
  height: calc(100% - 180px);
  overflow: scroll;
}
</style>
