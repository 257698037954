import "vant/es/toast/style";
import _Toast from "vant/es/toast";
import TopBar from '../../components/TopBar';
export default {
  name: 'AddAccount',
  components: {
    TopBar: TopBar
  },
  data: function data() {
    return {
      top_title: '新用户注册',
      btns: [{
        text: '',
        functionName: ''
      }],
      phone: '',
      captcha: '',
      password: '',
      confirmPassword: '',
      checked: false,
      type: 'password',
      type2: 'password',
      rightIcon2: require('../../assets/images/icon/eyes_off.png'),
      rightIcon: require('../../assets/images/icon/eyes_off.png')
    };
  },
  methods: {
    click_right_icon: function click_right_icon() {
      if (this.type === 'password') {
        this.type = 'text';
        this.rightIcon = require('../../assets/images/icon/eyes_on.png');
      } else {
        this.type = 'password';
        this.rightIcon = require('../../assets/images/icon/eyes_off.png');
      }
    },
    click_right_icon2: function click_right_icon2() {
      if (this.type2 === 'password') {
        this.type2 = 'text';
        this.rightIcon2 = require('../../assets/images/icon/eyes_on.png');
      } else {
        this.type2 = 'password';
        this.rightIcon2 = require('../../assets/images/icon/eyes_off.png');
      }
    },
    onSubmit: function onSubmit() {
      if (this.checked) {} else {
        _Toast.fail('请勾选协议！');
      }
    },
    goTo: function goTo(path) {
      this.$router.push({
        path: path
      });
    }
  }
};