import "core-js/modules/es.function.name";
import "core-js/modules/es.regexp.exec";
import "core-js/modules/es.string.replace";
import "vant/es/toast/style";
import _Toast from "vant/es/toast";
import { ref, reactive, onMounted, nextTick } from 'vue';
import { useStore } from 'vuex';
import { useRouter, useRoute } from 'vue-router';
import { getTemplateJSON, replaceData } from '../../util/template';
import { getSingleResume, saveResume } from '../../api/MyResume';
export default {
  name: 'OptimizeIndex',
  components: {},
  setup: function setup() {
    var $store = useStore();
    var router = useRouter();
    var route = useRoute();
    var scrollText = $store.state.scrollText;
    var state = reactive({
      current: 0,
      images: [{
        img: 'https://img01.yzcdn.cn/vant/apple-1.jpg',
        name: 'template1'
      }, {
        img: 'https://img01.yzcdn.cn/vant/apple-2.jpg',
        name: 'template2'
      }, {
        img: 'https://storage.360buyimg.com/channel2022/jd_home/0.0.18/assets/img/613f39ecb60e42dfec798a4ffc0bc012.png',
        name: 'template3'
      }, {
        img: 'https://img01.yzcdn.cn/vant/apple-2.jpg',
        name: 'template4'
      }]
    });

    var onChange = function onChange(index) {
      state.current = index;
    };

    var toTemplate = function toTemplate() {
      router.push('/resume_score');
    };

    var BackScore = function BackScore() {
      router.go(-1); // router.push('/resume_template')
    };

    var toDownLoad = function toDownLoad() {
      // $store.commit('setDownLoad', true)
      var name = state.images[state.current].name;
      var data = getTemplateJSON(name);
      getSingleResume(route.query.id).then(function (res) {
        var resData = JSON.parse(res.data.resume_data);
        var html = replaceData(data, resData);
        var newStr = data.html.replace('HTMLContent', html);
        var resume = {
          'id': route.query.id,
          'template': newStr,
          //简历模板内容
          'template_name': name //简历模板名称

        };
        saveResume(resume).then(function (res2) {
          _Toast.success('应用成功！');

          router.push({
            path: '/editresume',
            query: {
              id: route.query.id,
              status: route.query.status,
              template_name: name
            }
          });
        });
      });
    };

    onMounted(function () {
      state.notice_text = $store.state.scrollText;
    });
    return {
      onChange: onChange,
      state: state,
      scrollText: scrollText,
      toTemplate: toTemplate,
      BackScore: BackScore,
      toDownLoad: toDownLoad
    };
  }
};