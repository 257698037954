import "core-js/modules/es.array.find-index";
import "core-js/modules/es.array.splice";
import "core-js/modules/es.function.name";
import "vant/es/toast/style";
import _Toast from "vant/es/toast";
import "vant/es/dialog/style";
import _Dialog from "vant/es/dialog";
import "vant/es/notify/style";
import _Notify from "vant/es/notify";
import _toConsumableArray from "D:/\u7B80\u5386GPT/resumechatgpt/node_modules/@babel/runtime/helpers/esm/toConsumableArray";
import _objectSpread from "D:/\u7B80\u5386GPT/resumechatgpt/node_modules/@babel/runtime/helpers/esm/objectSpread2";
import store from '../../../store';
import { mapState } from 'vuex';
import TopBar from 'components/TopBar.vue';
import { getSingleResume, saveResume } from 'api/MyResume';
export default {
  name: 'index',
  components: {
    TopBar: TopBar
  },
  data: function data() {
    return {
      show: false,
      elementOrder: [1, 2, 3],
      choseElement: '',
      top_title: '简历编辑',
      btns: [{
        text: '预览'
      }, {
        text: '保存',
        functionName: 'upDateResume'
      }, {
        text: '发布'
      }],
      choseElementName: '',
      skills: [{
        text: '语言能力'
      }, {
        text: '个人作品'
      }, {
        text: '技能证书'
      }]
    };
  },
  computed: _objectSpread({}, mapState(['resumeInfo', 'scrollText'])),
  mounted: function mounted() {// this.getinfo()
  },
  methods: {
    goToDetail: function goToDetail(type, index) {
      var path = '';

      if (type === '教育经历') {
        path = 'educationalexperience';
      } else if (type === '工作经历') {
        path = 'workexperience';
      } else if (type === '项目经历') {
        path = 'projectexperience';
      } else if (type === '校园经历') {
        path = 'campusexperience';
      } else {
        path = 'other';
      }

      this.$router.push({
        path: path,
        query: {
          index: index,
          id: this.$route.query.id,
          type: type
        }
      });
    },
    getinfo: function getinfo() {
      var _this = this;

      if (!store.state.resumeInfo) {
        getSingleResume(this.$route.query.id).then(function (res) {
          var resData = JSON.parse(res.data);

          _this.$store.commit('getResumeInfo', resData);
        });
      }
    },
    goto: function goto(path) {
      this.$router.push({
        path: path,
        query: {
          id: this.$route.query.id
        }
      });
    },
    showPopup: function showPopup(i, name) {
      this.show = true;
      this.choseElement = i;
      this.choseElementName = name;
    },
    moveUp: function moveUp() {
      var arr = _toConsumableArray(this.elementOrder);

      var num = arr[this.choseElement];

      if (num === 1) {
        _Notify({
          type: 'warning',
          message: '无法上移，已经到顶了！'
        });

        this.show = false;
      } else if (num > 1) {
        var temp = arr[this.choseElement];
        var i = arr.findIndex(function (d) {
          return d === num - 1;
        });
        arr[this.choseElement] = arr[i];
        arr[i] = temp;
        this.elementOrder = arr;
        this.show = false;
      }
    },
    moveDown: function moveDown() {
      var arr = _toConsumableArray(this.elementOrder);

      var num = arr[this.choseElement];

      if (num === this.elementOrder.length) {
        _Notify({
          type: 'warning',
          message: '无法下移，已经到底了！'
        });

        this.show = false;
      } else if (num < this.elementOrder.length) {
        var temp = arr[this.choseElement];
        var i = arr.findIndex(function (d) {
          return d === num + 1;
        });
        arr[this.choseElement] = arr[i];
        arr[i] = temp;
        this.elementOrder = arr;
        this.show = false;
      }
    },
    deleteExperienced: function deleteExperienced() {
      var that = this;
      var name = that.modules[that.choseElement].bigTitle;
      return;

      _Dialog.confirm({
        title: '提示：',
        message: '是否删除' + name + '?'
      }).then(function () {
        that.modules.splice(that.choseElement, 1);
        that.show = false;

        _Toast.success('删除成功！');
      }).catch(function () {// on cancel
      });
    },
    addModule: function addModule() {
      this.$router.push({
        path: '/other',
        query: {
          flag: 'addModule'
        }
      });
    },
    goBack: function goBack() {
      this.$router.go(-1);
    },
    upDateResume: function upDateResume() {
      var _this2 = this;

      var arr = _toConsumableArray(store.state.resumeInfo.experienced);

      var baseInfo = {
        name: '基本信息',
        content: _objectSpread({}, store.state.resumeInfo.baseInfo)
      };
      var introduction = {
        name: '个人简介',
        content: [store.state.resumeInfo.introduction]
      };
      arr.push(baseInfo);
      arr.push(introduction);
      var data = {
        id: this.$route.query.id,
        name: this.$route.query.name,
        data: JSON.stringify(arr)
      };
      saveResume(data).then(function (res) {
        _Toast.success('保存成功！');

        _this2.$store.commit('setEditStatus', false);
      });
    }
  }
};