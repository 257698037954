import { createRouter, createWebHashHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    redirect: '/login'
  },
  {
    path: '/layout',
    name: 'layout',
    component: () => import ('./../layout/index'),

    redirect: 'resume_optimize',
    children: [
      {
        path: '/resume_optimize',
        name: 'resume_optimize',
        component: () => import ('./../views/ResumeOptimization/ResumeOptimize/OptimizeIndex')
      }, {
        path: '/resume_download',
        name: 'ResumeDownLoad',
        component: () => import ('../views/ResumeOptimization/ResumeOptimize/ResumeDownLoad')
      }, {
        path: '/download_finish',
        name: 'DownloadFinish',
        component: () => import ('../views/ResumeOptimization/ResumeOptimize/DownloadFinish.vue')
      }, {
        path: '/mycv',
        name: 'mycv',
        component: () => import ('./../views/MyCV/index'),
        meta: {
          requireAuth: true
        }
      }, {
        path: '/interview',
        name: 'interview',
        component: () => import ('./../views/Interview/index'),
        meta: {
          requireAuth: true
        }

      }, {
        path: '/user_center',
        name: 'user_center',
        component: () => import ('../views/AccountCenter/UserCenter/index'),
        meta: {
          requireAuth: true
        },
      },
      {
        path: '/about_us',
        name: 'AboutUs',
        component: () => import ('../views/AccountCenter/AboutUs/index'),
        meta: {
          requireAuth: true
        },
      },
      {
        path: '/my_config',
        name: 'MyConfig',
        component: () => import ('../views/AccountCenter/MyConfig/index'),
        meta: {
          requireAuth: true
        },
      },
      {
        path: '/my_order',
        name: 'MyOrder',
        component: () => import ('../views/AccountCenter/MyOrder/index'),
        meta: {
          requireAuth: true
        },

      },
      {
        path: '/use_record',
        name: 'UseRecord',
        component: () => import ('../views/AccountCenter/UseRecord/index'),
        meta: {
          requireAuth: true
        },

      },
    ],
  }, {
    path: '/interview_chat',
    name: 'interview_chat',
    component: () => import ('./../views/Interview/InterviewChat.vue'),
    meta: {
      requireAuth: true
    }
  }, {
    path: '/interview_template',
    name: 'interview_template',
    component: () => import ('./../views/Interview/InterviewTemplate.vue'),
    meta: {
      requireAuth: true
    }
  }, {
    path: '/interview_template_list',
    name: 'interview_template_list',
    component: () => import ('./../views/Interview/InterviewTemplateList.vue'),
    meta: {
      requireAuth: true
    }
  },
  {
    path: '/order_result',
    name: 'OrderResult',
    component: () => import ('../views/AccountCenter/OrderResult/index'),
    meta: {
      requireAuth: true
    },
  },
  {
    path: '/edit_res',
    name: 'EditRes',
    component: () => import ('../views/ResumeOptimization/ResumeOptimize/EditRes')
  },
  {
    path: '/loading',
    name: 'Loading',
    component: () => import ('../views/ResumeOptimization/ResumeOptimize/Loading')
  },
  {
    path: '/loading_finish',
    name: 'LoadingFinished',
    component: () => import ('../views/ResumeOptimization/ResumeOptimize/LoadingFinished')
  },
  {
    path: '/resume_score',
    name: 'ResumeScore',
    component: () => import ('../views/ResumeOptimization/ResumeOptimize/ResumeScore')
  },
  {
    path: '/resume_template',
    name: 'ResumeTemplate',
    component: () => import ('views/TemplateManage/ResumeTemplate.vue')
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import ('./../views/Login/index')
  },
  {
    path: '/editresume',
    name: 'EditResume',
    component: () => import ('./../views/ResumeOptimization/EditResume/index'),
    meta: {
      requireAuth: true
    }
  },
  {
    path: '/baseinfo',//编辑个人信息
    name: 'BaseInfo',
    component: () => import ('./../views/ResumeOptimization/EditResume/BaseInfo'),
    meta: {
      requireAuth: true
    }
  },
  {
    path: '/biography',//编辑个人简介
    name: 'Biography',
    component: () => import ('./../views/ResumeOptimization/EditResume/Biography'),
    meta: {
      requireAuth: true
    }
  },
  {
    path: '/educationalexperience',//编辑教育经历
    name: 'EducationalExperience',
    component: () => import ('./../views/ResumeOptimization/EditResume/EducationalExperience'),
    meta: {
      requireAuth: true
    }
  },
  {
    path: '/workexperience',//编辑工作经历
    name: 'WorkExperience',
    component: () => import ('./../views/ResumeOptimization/EditResume/WorkExperience'),
    meta: {
      requireAuth: true
    }
  },
  {
    path: '/projectexperience',//编辑项目经历
    name: 'ProjectExperience',
    component: () => import ('./../views/ResumeOptimization/EditResume/ProjectExperience'),
    meta: {
      requireAuth: true
    }
  },
  {
    path: '/campusexperience',//编辑校园经历
    name: 'CampusExperience',
    component: () => import ('./../views/ResumeOptimization/EditResume/CampusExperience'),
    meta: {
      requireAuth: true
    }
  },
  {
    path: '/other',//编辑其它经历
    name: 'Other',
    component: () => import ('./../views/ResumeOptimization/EditResume/Other'),
    meta: {
      requireAuth: true
    }
  },
  {
    path: '/languageskills',//编辑语言能力
    name: 'LanguageSkills',
    component: () => import ('./../views/ResumeOptimization/EditResume/LanguageSkills'),
    meta: {
      requireAuth: true
    }
  },
  {
    path: '/individualworks',//编辑个人作品
    name: 'IndividualWorks',
    component: () => import ('./../views/ResumeOptimization/EditResume/IndividualWorks'),
    meta: {
      requireAuth: true
    }
  },
  {
    path: '/expertise',//编辑专业技能
    name: 'Expertise',
    component: () => import ('./../views/ResumeOptimization/EditResume/Expertise'),
    meta: {
      requireAuth: true
    }
  },
  //登录
  {
    path: '/addaccount',
    name: 'AddAccount',
    component: () => import ('./../views/Login/AddAccount')
  },

  {
    path: '/forgot',
    name: 'Forgot',
    component: () => import ('./../views/Login/Forgot')
  },
  {
    path: '/useragreement',
    name: 'UserAgreement',
    component: () => import ('./../views/Login/UserAgreement')
  },
  //用户中心
  {
    path: '/purchase',
    name: 'Purchase',
    component: () => import ('./../views/AccountCenter/Purchase/index'),
    meta: {
      requireAuth: true
    }
  }, //意见反馈
  {
    path: '/opinion',
    name: 'Opinion',
    component: () => import ('./../views/AccountCenter/Opinion/index'),
    meta: {
      requireAuth: true
    }
  },
  {
    path: '/resumepreview',
    name: 'ResumePreview',
    component: () => import ('./../views/TemplateManage/ResumePreview'),
    meta: {
      requireAuth: true
    }
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router

