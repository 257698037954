<template>
  <div class='container' style='padding-bottom: 0;position: relative'>
    <TopBar :top_title='top_title' :btns='btns' :hiddenNotice='true'
            style="box-shadow: 0px 1px 9px 0px rgba(7,95,184,0.13);"></TopBar>
<!--    <div class="shadow"></div>-->
    <div class='container_'>
      <div class="chatwindow">
        <div class="chat_content" id="chat_window">
          <div
            v-for="(item, index) of chat_list"
            :key="item.id"
          >
            <div class="ask_bubble" v-if="item.type == 'ask'">
              <div class="head_img"></div>
              <div class="chat_ask" v-if="item.content != ''">
                <vuetyped @preStringTyped="toBottom()" @onStringTyped="toBottom()" :strings="[item.content]" :loop="false" :smart-backspace="true" :showCursor="false">
                  <div class="typing" />
                </vuetyped>
              </div>
              <div class="loading_status" v-if="item.content === ''" style="font-size: 26px;margin-left: 10px">
                <van-loading type="spinner" />
              </div>
            </div>
            <div class="answer_bubble" v-if="item.type == 'answer'">
              <div class="chat_answer">{{ item.content }}</div>
              <div class="user_head_img" :style="{'background-image':'url(' + imagePath + ')'}"></div>
            </div>
          </div>
        </div>
        <div class="chat_but">
          <div class="chat_input">
            <input type="text" v-model="chat_msg" :disabled="chat_disable == 1"
                   :placeholder="chat_disable === 1 ? '暂时不可输入...' : '请输入...' ">
            <img class="send_img" v-if="chatflag === true" @click="sendMsg" src="../../assets/images/send.png" alt="">
            <img class="send_img" v-if="chatflag === false" @click="sendMsgTwice" src="../../assets/images/send.png" alt="">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TopBar from '../../components/TopBar'
import { Notify } from 'vant'
import { getMyInfo, InterViewChat, wechatPay } from 'api/MyResume'
import store from '@/store'

export default {
  components: {
    TopBar: TopBar,
  },

  data () {
    return {
      top_title: '面试助手',
      userInfo: {
        avatar: ''
      },
      btns: [{
        text: '',
        functionName: ''
      }],
      imagePath: store.state.userInfo.headimgurl,
      userid: store.state.userInfo.id,
      conversation_id: '',
      chat_disable: 0,
      chat_msg: '',
      chat_list: [],
      chatstatus: false,
      chatflag: true,
    }
  },
  methods:
    {
    getUserInfo(){
      getMyInfo(localStorage.getItem('id')).then((value) =>{
        this.imagePath = value.data.headimgurl
        this.userid = localStorage.getItem('id')
      })
    },
    sendMsgTwice(){
      Notify("请稍等")
    },
    getQuestion(){
      this.chat_msg = this.$route.query.question
      this.ChatStatus()
    },
    async getMsg () {
      this.chatflag = false
      this.chat_disable = 1
      InterViewChat(this.conversation_id,this.chat_msg,localStorage.getItem('id')).then(resp => {
        if (resp.status === 'success' && resp.data){
          this.chat_list.push({
            type: 'ask',
            content: resp.data
          })
          this.chat_list = this.chat_list.filter(t => t.content !== '')
          this.$nextTick(() => {
            this.toBottom()
          })
          this.chat_msg = ''
          this.chatflag = true
          this.chat_disable = 0
        }else{
          this.chatflag = false
          Notify(resp.msg)
        }
      }).catch(error => {
        this.chatflag = false
        Notify("服务器繁忙")
      })
    },
    sendMsg () {
      if (this.chatflag === false){
        return Notify("服务器繁忙")
      }
      if (this.chat_msg === '') {
        Notify('输入为空!!')
      } else {

        this.chat_list.push({
          type: 'answer',
          content: this.chat_msg
        })
        this.chat_list.push({
          type: 'ask',
          content: ''
        })
        this.$nextTick(() => {
          this.toBottom()
        })
        this.chatflag = false
        this.chat_disable = 1
        this.getMsg()
      }
    },
    async ChatStatus () {
      this.chatflag = false
      this.chat_disable = 1
      if (this.conversation_id == '') {
        this.chat_list.push({
          type: 'ask',
          content: ''
        })
        InterViewChat(this.conversation_id,this.chat_msg,localStorage.getItem('id')).then(resp => {
          if (resp.status === 'success' && resp.data){
            this.chat_list.push({
              type: 'ask',
              content: resp.data
            })
            this.chat_list = this.chat_list.filter(t => t.content !== '')
            this.toBottom()
            this.conversation_id = resp.conversation_id
            this.chat_msg = ''
            this.chatflag = true
            this.chat_disable = 0
          }else{
            this.chatflag = false
            Notify(resp.msg)
          }
        }).catch(error => {
          Notify("服务器繁忙!")
          this.chat_list = this.chat_list.filter(t => t.content !== '')
        })
      }
    },
    toBottom () {
      this.$nextTick(() => {
        var scrollDom = document.getElementById('chat_window')
        scrollDom.scrollTop = scrollDom.scrollHeight
      })
    },
  },
  mounted () {
    this.getQuestion();
    this.getUserInfo()
  },
  updated () {
    this.$nextTick(function () {
      var scrollDom = document.getElementById('chat_window')
      scrollDom.scrollTop = scrollDom.scrollHeight
    });
  },
}
</script>

<style scoped>
.shadow {
  width: 100%;
  height: 20px;
  position: fixed;
  top: 50px;
  background-color: #fff;
  z-index: 9;
  border-radius: 0 0px 10px 10px;
  box-shadow: 0 1px 20px 0px #dedede;
}

.chat_answer {
  padding: 15px;
  min-height: 20px;
  max-width: 60%;
  background: #0A41DE;
  border-radius: 20px 0px 20px 20px;

  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #FFFFFF;
  margin: 10px 15px 5px 0px;
}

.user_head_img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  /*background-image: url("../../assets/images/user_head.png");*/
  background-repeat: no-repeat;
  background-size: cover;
}

.answer_bubble {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.chat_ask {
  max-width: 60%;
  padding: 10px;
  margin-left: 15px;
  min-height: 20px;
  background: rgba(245, 249, 250, 1);
  border-radius: 0px 18px 18px 18px;

  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: rgba(120, 120, 120, 1);
  line-height: 20px;
}

.mask {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #0b0b0b;
  width: 100%;
  height: 100%;
  z-index: 99999;
  opacity: .7;
}

.ask_bubble {
  display: flex;
  /*align-items: center;*/
}

.head_img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-image: url("../../assets/images/manage_head.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.send_img {
  width: 50px;
}

.chat_input {
  border-radius: 20px 20px 0px 0px;
  background-color: rgba(242, 242, 242, 1);
  padding: 15px;
  width: 100%;
  position: fixed;
  bottom: 0;
  display: flex;
}

.chat_input button {
  width: 134px;
  height: 50px;
  background: #FFFFFF;
  border: 1px solid #0A41DE;
  border-radius: 25px;
  color: #0A41DE;
}

.chat_input input {
  width: 100%;
  height: 40px;
  background: #FFFFFF;
  border: 1px solid #E3E5EC;
  border-radius: 10px;
  outline: none;
  font-size: 14px;
  padding-left: 10px;
  color: #C4C8D3;
  word-wrap: break-word;
}

.chat_but {
  position: relative;
  width: 100%;
  height: 80px;
  background: #FFFFFF;
  border-radius: 0px 0px 18px 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 80px;
}

.chat_content {
  padding: 20px;
  width: 100%;
  /*background: #EBF0FD;*/
  overflow: auto;
  white-space: pre-wrap;
}

.chat_header {
  width: 100%;
  height: 60px;
  background: #FFFFFF;
  border-radius: 18px 18px 0px 0px;
  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #222222;
  text-align: center;
  line-height: 60px;
}

.chatwindow {
  width: 100%;
  height: 180px;
  background: #FFFFFF;
  border-radius: 18px;
  z-index: 999991;
}

.row_content {
  flex-shrink: 0;
}

.quit_block {
  width: 100%;
  height: 50px;
  background-color: #fff;
  border-radius: 10px;
  margin-top: 10px;
  text-align: center;
  line-height: 50px;
}

.user_name_ img {
  width: 18px;
  margin-left: 5px;
}

.user_name_ {
  display: flex;
  align-items: center;
  color: rgba(130, 130, 130, 1);
  padding: 15px 0;
}

.user_name {
  padding: 15px 0;
}

.row_title {
  height: 45px;
  line-height: 45px;
}

.row_info_ {
  display: flex;
  width: 100%;
  justify-content: space-between;
  border-bottom: 1px solid rgba(250, 250, 250, 1);
}

.row_info {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding-bottom: 14px;
  border-bottom: 1px solid rgba(250, 250, 250, 1);
}

.avatar_upload {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}

.avatar {
  width: 55px;
  height: 55px;
  border-radius: 50%;
  overflow: hidden;
  position: relative;
}

.info_block {
  padding: 20px 20px 0 20px;
  border-radius: 10px;
  margin-top: 10px;
  width: 100%;
  min-height: 120px;
  background-color: #fff;
}

.container_ {
  background-color: #fff;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding-top: 50px;
}
</style>
