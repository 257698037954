import "vant/es/toast/style";
import _Toast from "vant/es/toast";
import _objectSpread from "D:/\u7B80\u5386GPT/resumechatgpt/node_modules/@babel/runtime/helpers/esm/objectSpread2";
import store from '../../../store';
import TopBar from '../../../components/TopBar';
export default {
  //基本信息
  name: 'LanguageSkills',
  components: {
    TopBar: TopBar
  },
  data: function data() {
    return {
      top_title: '语言能力',
      btns: [{
        text: '确认',
        functionName: 'saveBaseInfo'
      }],
      listeningAndSpeakingAbility: [{
        text: '一般',
        value: '一般'
      }, {
        text: '良好',
        value: '良好'
      }, {
        text: '很好',
        value: '很好'
      }, {
        text: '非常好',
        value: '非常好'
      }],
      showPicker: false,
      showPicker2: false,
      info: {
        key1: '',
        key2: '',
        key3: ''
      }
    };
  },
  mounted: function mounted() {
    this.index = this.$route.query.index;
    this.flag = !!this.index;

    if (this.flag) {
      this.getInfo(this.$route.query.type);
    }
  },
  methods: {
    getInfo: function getInfo() {
      this.info = _objectSpread({}, store.state.resumeInfo.languageSkills[this.index]);
    },
    onConfirm: function onConfirm(selectedOptions) {
      this.info.key2 = selectedOptions.text;
      this.showPicker = false;
    },
    onConfirm2: function onConfirm2(selectedOptions) {
      this.info.key3 = selectedOptions.text;
      this.showPicker2 = false;
    },
    goBack: function goBack() {
      this.$router.go(-1);
    },
    saveBaseInfo: function saveBaseInfo() {
      var obj = _objectSpread({}, this.info);

      _Toast.success('提交成功！');

      this.$store.commit('saveSkills', {
        data: obj,
        key: 'languageSkills'
      });
      this.goBack();
    }
  }
};