<template>
  <div class='container'>
    <TopBar :top_title='top_title' :btns='btns' :hiddenNotice='true'></TopBar>
    <div class='input_container'>
      <van-field
        v-model='value1'
        label='选择反馈类型'
        right-icon='arrow'
        readonly
        placeholder=''
        @click='show = true'
      />
    </div>
    <div class='input_container'>
      <van-field
        v-model='message'
        rows='5'
        autosize
        label=''
        type='textarea'
        maxlength='300'
        placeholder='请输入反馈信息'
        show-word-limit
      />
    </div>
    <div class='input_container'>
      <van-field
        v-model='email'
        label=''
        placeholder='手机/邮箱/QQ（选填）'
      />
    </div>
    <div class='upload_title'>上传图片（最多6张）</div>
    <div class='img_container'>
      <div class='img' v-if='imgs.length < 6'>
        <div class='img_upload'>
          <van-icon name="plus" />
        </div>
        <van-uploader class='uploader' :before-read="beforeRead" @oversize="onOversize" accept="image/*" :max-size="10240 * 1024"></van-uploader>
      </div>
      <div class='img' v-for='i of 6'>
        <div v-if='imgs[i -1]' class='delete_btn_' @click='deleteImage(i -1)'>
          <van-icon name="minus" />
        </div>
        <van-image
          width='100%'
          height='100%'
          @click='showImg(i -1)'
          fit='cover'
          v-if='imgs[i -1]'
          :src='imgs[i -1]'
        />
      </div>
    </div>
    <van-popup v-model:show='show' position='bottom' class='info_picker_dialog' :style="{ height: '250px'}">
      <van-picker
        :columns='types'
        class='info_picker'
        @confirm='onConfirm'
        @cancel='show = false'
      />
    </van-popup>
  </div>
</template>

<script>
import TopBar from '../../../components/TopBar'
import { toRaw } from '@vue/reactivity'
import { Notify,ImagePreview} from 'vant'
import { uploadFile, userFeedback } from 'api/utils'
import store from '@/store'
import { nextTick } from 'vue'
export default {
  name: 'index',
  components: {
    TopBar: TopBar
  },
  data () {
    return {
      types: [
        {
          text: '产品体验',
          value: '产品体验'
        },
        {
          text: '产品功能',
          value: '产品功能'
        },
        {
          text: '优惠活动',
          value: '优惠活动'
        },
        {
          text: '其它问题',
          value: '其它问题'
        }
      ],
      top_title: '意见反馈',
      message: '',
      value1: '',
      email: '',
      show: false,
      btns: [{
        text: '提交',
        functionName: 'saveInfo'
      }],
      imgs: [],
      imgs_upload:[]
    }
  },
  methods: {
    beforeRead(file){
      if (Array.isArray(file)) {
        file.forEach(item => {
          if (item.type !== 'image/jpeg') {
            console.log("请上传 image 格式图片")

            return false
          }
        })
        if (file.type !== 'image/jpeg') {
          console.log("请上传 image 格式图片")
          return false
        }
      }
      let reader = new FileReader();
      let that = this
      reader.readAsDataURL(file);//读取图像文件 result 为 DataURL, DataURL 可直接 赋值给 img.src
      reader.onload = function (event) {
        if (that.imgs.length < 7){
          that.imgs.push(event.target.result)
        }
      }
    },
    deleteImage(index){
      this.imgs.splice(index,1)
    },
    showImg(index){
      ImagePreview({
        images: [...this.imgs],
        startPosition: index,
      });
    },
    onOversize(){
      Notify({
        type: 'warning',
        message: '文件不能超过10M！'
      })
    },
    onConfirm (data) {
      this.value1 = data.text
      this.show = false
    },
    base64toFile(dataurl, filename){
      let arr = dataurl.split(',')
      let mime = arr[0].match(/:(.*?);/)[1]
      let suffix = mime.split('/')[1]
      let bstr = atob(arr[1])
      let n = bstr.length
      let u8arr = new Uint8Array(n)
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
      }
      return new File([u8arr], `${filename}.${suffix}`, {
        type: mime
      })
    },
    saveInfo () {
      const formData = new FormData()
      let that = this
      if (this.value1 === '' || this.message === ''){
        Notify('请完善反馈信息')
        console.log(this.imgs_upload)
      }else{
        let obj = {
          user_id: localStorage.getItem('id'),
          images: [],
          type: this.value1,
          content: this.message,
          contact: this.email
        }
        this.imgs.forEach(function (item, index) {
          let files = that.base64toFile(item, 'filename')
          formData.append('file', files)
          uploadFile(formData).then((res) => {
            that.imgs_upload.push(res.data)
          })
        })

        obj.images = toRaw(that.imgs_upload)

        setTimeout(()=>{
          obj.images = JSON.stringify(obj.images)
          userFeedback(obj).then((res)=>{
            if (res.status === 'success'){
              Notify({ type: 'success', message: '提交成功' });
            }
            this.$router.go(0)
          })
        },1000)


      }
    }
  }
}
</script>

<style scoped>
.uploader{
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  opacity: 0;
}
.img_upload{
  width: 100%;
  height: 100%;
  background-color: #fff;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  color: #BDBDBD;
}
.upload_title {
  color: #2A2E31;
  margin: 30px 0;
  font-size: 14px;
  padding-left: 15px;
}

.img {
  width: 100px;
  height: 100px;
  margin-bottom: 10px;
  position: relative;
}
.delete_btn_{
  position: absolute;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: rgba(255,0,0,0.8);
  top: 5px;
  right: 5px;
  z-index: 9;
  text-align: center;
  line-height: 20px;
  color: #FFFFFF;
}

.img_container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.container {
  height: 100%;
  background-color: #F8F8F8;
  padding: 0 15px;
  overflow: scroll;
}

.input_container {
  width: 100%;
  margin-top: 15px;
  padding: 5px 15px;
  background-color: #fff;
  border-radius: 8px;
}
</style>
