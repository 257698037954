import "core-js/modules/es.function.name";
import _objectSpread from "D:/\u7B80\u5386GPT/resumechatgpt/node_modules/@babel/runtime/helpers/esm/objectSpread2";
import _defineProperty from "D:/\u7B80\u5386GPT/resumechatgpt/node_modules/@babel/runtime/helpers/esm/defineProperty";
import "vant/es/circle/style";
import _Circle from "vant/es/circle";

var _components;

import { mapState } from 'vuex';
import store from '../../../store';
import 'vant/lib/index.css';
import MyLoading from '../../../components/Loading';
import axios from 'axios';
import { getSingleResume } from '../../../api/MyResume';
export default {
  name: 'Loading',
  components: (_components = {}, _defineProperty(_components, _Circle.name, _Circle), _defineProperty(_components, "MyLoading", MyLoading), _components),
  data: function data() {
    return {
      top_title: '简历编辑',
      currentRate: 20,
      file_name: '',
      file_id: '',
      flag: true,
      status: '',
      template_name: ''
    };
  },
  computed: _objectSpread({}, mapState(['scrollText'])),
  methods: {
    add: function add() {
      this.currentRate = this.currentRate + 10;
    },
    reduce: function reduce() {
      this.currentRate = this.currentRate - 10;
    },
    BackIndex: function BackIndex() {
      this.$router.go(-1);
    },
    getFileInfo: function getFileInfo() {
      var _this = this;

      getSingleResume(store.state.fileId).then(function (resp) {
        _this.template_name = resp.data.template_name;
        _this.status = resp.data.status;
      }).catch(function (error) {});
      this.file_name = store.state.fileName;
      this.file_id = store.state.fileId;
    },
    toEdit: function toEdit() {
      this.$router.push({
        path: 'editresume',
        query: {
          id: this.file_id,
          name: this.file_name,
          status: this.status,
          template_name: this.template_name
        }
      });
    }
  },
  mounted: function mounted() {
    this.getFileInfo();
  }
};