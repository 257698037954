import "core-js/modules/es.function.name";
import "vant/es/dialog/style";
import _Dialog from "vant/es/dialog";
import _objectSpread from "D:/\u7B80\u5386GPT/resumechatgpt/node_modules/@babel/runtime/helpers/esm/objectSpread2";
import { mapState } from 'vuex';
import store from '../store';
export default {
  name: 'TopBar',
  props: {
    top_title: String,
    btns: Array,
    hiddenNotice: Boolean
  },
  computed: _objectSpread({}, mapState(['scrollText'])),
  methods: {
    goBack: function goBack() {
      var that = this;

      if (store.state.isEdit && this.$route.name === "EditResume") {
        _Dialog.confirm({
          title: '提示',
          message: '您修改的内容暂未保存，是否退出？'
        }).then(function () {
          that.$router.go(-1);
        }).catch(function () {// on cancel
        });
      } else {
        that.$router.go(-1);
      }
    },
    btnFunction: function btnFunction(name) {
      this.$parent[name]();
    }
  }
};