import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "container"
};
var _hoisted_2 = {
  class: "my_form"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_TopBar = _resolveComponent("TopBar");

  var _component_van_field = _resolveComponent("van-field");

  var _component_van_uploader = _resolveComponent("van-uploader");

  var _component_van_cell_group = _resolveComponent("van-cell-group");

  var _component_van_form = _resolveComponent("van-form");

  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_TopBar, {
    top_title: $data.top_title,
    btns: $data.btns
  }, null, 8, ["top_title", "btns"]), _createElementVNode("div", _hoisted_2, [_createVNode(_component_van_form, null, {
    default: _withCtx(function () {
      return [_createVNode(_component_van_cell_group, {
        inset: ""
      }, {
        default: _withCtx(function () {
          return [_createVNode(_component_van_field, {
            modelValue: $data.info.key1,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
              return $data.info.key1 = $event;
            }),
            name: "作品名称",
            label: "作品名称",
            placeholder: "作品名称",
            rules: [{
              required: true,
              message: '请填写作品名称'
            }]
          }, null, 8, ["modelValue"]), _createVNode(_component_van_field, {
            modelValue: $data.info.key2,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = function ($event) {
              return $data.info.key2 = $event;
            }),
            type: "textarea",
            class: "border",
            row: 2,
            name: "作品描述",
            label: "作品描述",
            placeholder: "请输入作品描述"
          }, null, 8, ["modelValue"]), _createVNode(_component_van_uploader, {
            "after-read": $options.afterRead
          }, null, 8, ["after-read"])];
        }),
        _: 1
      })];
    }),
    _: 1
  })])]);
}