<template>
  <div class='container' style='padding-bottom: 0;'>
    <TopBar :top_title='top_title' :btns='btns' :hiddenNotice='true'></TopBar>
    <div class='container_'>
      <div class="info_block">
        <div class="row_info">
          <div class="row_title">头像</div>
          <div class="row_content">
            <div class='avatar'>
              <van-image width='65' height='65' position='centerss' fit='cover' :src='imagePath' />
              <div class='avatar_upload'>
                <van-uploader :before-read="beforeRead" @oversize="onOversize" accept="image/*"
                              :max-size="10240 * 1024"></van-uploader>
                <Cropper v-if='CropperFlag' :imagePath="imagePath" @save="onSave" @cancel="onCancel"/>
              </div>
            </div>
          </div>
        </div>
        <div class="row_info_">
          <div class="user_name">昵称</div>
          <div class="user_name_">{{ nick_name }}</div>
        </div>
        <div class="row_info_">
          <div class="user_name">ID号</div>
          <div class="user_name_"><div class="user_name_val">{{ id }}</div> <img src="../../../assets/images/icon/lock.png" alt=""> </div>
        </div>
      </div>
      <div class="quit_block" @click="quit">退出登录</div>
    </div>
  </div>
</template>

<script>
import TopBar from '../../../components/TopBar'
import { Notify } from 'vant'
import { uploadFile } from 'api/utils'
import store from '@/store'
import { mapState } from 'vuex'
import Cropper from "vue3-cropper";
import { getMyInfo } from 'api/MyResume'

export default {
  name: 'Login',
  components: {
    TopBar: TopBar,
    Cropper:Cropper,
  },
  computed: {
    ...mapState(['resumeInfo','userInfo'])
  },
  data () {
    return {
      top_title: '我的设置',
      userInfo: {
        avatar: ''
      },
      CropperFlag: false,
      imagePath: '',
      nick_name:'',
      id:store.state.userInfo.id,
      btns: [{
        text: '保存',
        functionName: ''
      }],
    }
  },
  methods: {
    beforeRead (file) {
      if (Array.isArray(file)) {
        file.forEach(item => {
          if (item.type !== 'image/jpeg') {
            console.log('请上传 image 格式图片')
            return false
          }
        })
        if (file.type !== 'image/jpeg') {
          console.log('请上传 image 格式图片')
          return false
        }
      }
      let reader = new FileReader()
      let that = this
      console.log(file)
      reader.readAsDataURL(file)
      reader.onload = function (event) {
        that.CropperFlag = true
        that.imagePath = event.target.result
      }
      return true
    },
    onOversize () {
      Notify({
        type: 'warning',
        message: '文件不能超过10M！'
      })
    },
    onSave (res) {
      let that = this
      that.CropperFlag = false
      let files = that.base64toFile(res, 'filename')
      //上传
      const formData = new FormData()
      formData.append('file', files)
      uploadFile(formData).then((res) => {
        that.imagePath = store.state.staticPath + res.data
        let obj = { ...store.state.resumeInfo.baseInfo }
        obj.pic_path = that.imagePath
        that.$store.commit('saveBaseInfo', obj)
      })

    },
    onCancel (data) {
      console.log('onCancel')
      console.log(data)
    },
    quit(){
      window.location.href = 'http://files.haijigis.com:11117/UJL/#/login'
      // 清除状态保持
      window.localStorage.clear()
      window.sessionStorage.clear()
    },
    getInfo(){
      getMyInfo(localStorage.getItem('id')).then((value) =>{
        this.imagePath = value.data.headimgurl
        this.nick_name = value.data.nickname
        this.optimize_times = value.data.optimize_times
        this.vip_is = value.data.vip_is
        this.vip_exp_time = value.data.vip_exp_time
        this.id = localStorage.getItem('id')
      })
    }
  },
  mounted () {
    this.getInfo()
  }
}
</script>

<style scoped>
.row_content{
  flex-shrink: 0;
}
.quit_block{
  width: 100%;
  height: 50px;
  background-color: #fff;
  border-radius: 10px;
  margin-top: 10px;
  text-align: center;
  line-height: 50px;
}
.user_name_ img{
  width: 18px;
  margin-left: 5px;
}
.user_name_{
  display: flex;
  align-items: center;
  color: rgba(130, 130, 130, 1);
  padding: 15px 0;
}
.user_name{
  padding: 15px 0;
}
.row_title{
  height: 45px;
  line-height: 45px;
}
.row_info_{
  display: flex;
  width: 100%;
  justify-content: space-between;
  border-bottom: 1px solid rgba(250, 250, 250, 1);
}
.row_info{
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding-bottom: 14px;
  border-bottom: 1px solid rgba(250, 250, 250, 1);
}
.avatar_upload {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}
.avatar {
  width: 55px;
  height: 55px;
  border-radius: 50%;
  overflow: hidden;
  position: relative;
}
.info_block {
  padding: 20px 20px 0 20px;
  border-radius: 10px;
  margin-top: 10px;
  width: 100%;
  min-height: 120px;
  background-color: #fff;
}

.container_ {
  background-color: rgba(248, 248, 248, 1);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 15px;
  padding-top: 50px;

}
</style>
