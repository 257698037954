<template>
  <div class='container' style='padding-bottom: 0;'>
    <div class='container_'>
      <div class="title">支付结果</div>
      <div class="done">
        <img v-if="this.$route.query.trade_state === 'SUCCESS'" src="../../../assets/images/done.png" alt="">
        <img v-if="this.$route.query.trade_state === 'NOTPAY'" src="../../../assets/images/notdone.png" alt="">
        <p v-if="this.$route.query.trade_state === 'SUCCESS'">支付成功</p>
        <p v-if="this.$route.query.trade_state === 'NOTPAY'">未支付</p>
        <p style="font-size: 24px;margin: 20px 0 60px">￥{{ this.$route.query.total }}</p>
      </div>
      <div class="lines">
        <p class="line_" v-if="this.$route.query.trade_state === 'SUCCESS'">支付状态：<span style="color: rgba(42, 46, 49, 1);">已支付</span></p>
        <p class="line_" v-if="this.$route.query.trade_state === 'NOTPAY'">支付状态：<span style="color: rgba(42, 46, 49, 1);">未支付</span></p>
        <p class="line_">支付方式：<span style="color: rgba(42, 46, 49, 1);">微信支付</span></p>
        <p class="line_" v-if="this.$route.query.trade_state === 'SUCCESS'">支付时间：<span style="color: rgba(42, 46, 49, 1);">{{ this.$route.query.success_time }}</span></p>
        <p class="line_" style="display: flex">订单号：<span style="color: rgba(42, 46, 49, 1);">{{ this.$route.query.out_trade_no }}</span></p>
      </div>
      <div class="footer">
        <div class="back_btn" @click="back_order">返回</div>
      </div>
    </div>
  </div>
</template>

<script>
import TopBar from '../../../components/TopBar'

export default {
  name: 'AboutUs',
  components: {
    TopBar: TopBar
  },
  data () {
    return {}
  },
  methods: {
    back_order () {
      this.$router.push('/my_order')
    },
    getOrderOne(){
      console.log(this.$route.query)
    }
  },
  mounted () {
    this.getOrderOne()
  }
}
</script>

<style scoped>
.back_btn {
  width: 152px;
  height: 38px;
  background: rgba(7, 193, 96, 1);
  border-radius: 20px;
  text-align: center;
  line-height: 38px;
  margin: 70px auto 0;

  font-size: 14px;
  color: #fff;
}

.line_ {
  font-size: 14px;
  margin: 20px 0;
  color: rgba(130, 130, 130, 1);
}

.lines {
  margin: 0 auto;
}

.done {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.done img {
  width: 64px;
  margin: 60px 0;
}

.title {
  text-align: center;
  color: rgba(42, 46, 49, 1);
  font-size: 16px;
}

.container_ {
  background-color: #fff;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 25px;
}
</style>
