<template>
  <div class='container_'>
    <div class='top'>
      <TopBar :top_title='top_title' :btns='btns' :hiddenNotice='true'></TopBar>
      <div class="top_word">我的点数/会员套餐</div>
      <div class='userInfo'>
        <div class='avatar'>
          <van-image
            width='68'
            height='68'
            fit='cover'
            :src='head_img'
          />
        </div>
        <div class='info'>
          <div class='userName'>{{ nick_name }}</div>
          <div class='userDate'>
            <div class='userDate_left'>
              <span style='margin-right: 20px;'>点数：{{ optimize_times }}</span>
              <span>会员套餐：{{ vip_exp_time }}天</span>
            </div>
            <div class='userDate_record'>
              <div class='record_iocn'></div>
              记录
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class='bottom'>
      <div class='bottom_title_container'>
        <div class='bottom_title' @click='choseTab("package")' :style='{color:flag === "package"?"#2A2E31":"#828282"}'>
          购买会员套餐
          <div class='bottom_title_line' v-if='flag === "package"'></div>
        </div>
        <div class='bottom_title' @click='choseTab("pointCards")'
             :style='{color:flag === "pointCards"?"#2A2E31":"#828282"}'>购买点数
          <div class='bottom_title_line' v-if='flag === "pointCards"'></div>
        </div>
<!--        <div class='bottom_title' @click='choseTab("free")' :style='{color:flag === "free"?"#2A2E31":"#828282"}'>免费领点数-->
<!--          <div class='bottom_title_line' v-if='flag === "free"'></div>-->
<!--          <div class='free_point'></div>-->
<!--        </div>-->
      </div>
      <div class='options_container'>
        <div class='options_' :style='{width: width}'>
          <div v-for='(item,index) of options' :key='index' @click='choseOption(index,item)' :class='active === index ? "active_options" : "options" '>
            <div class='options_top'>{{ item.text }}</div>
            <div class='options_mid'><span>￥</span>{{ item.discountedPrice }}</div>
            <div class='options_bot'>￥{{ item.price }}</div>
          </div>
        </div>
      </div>
      <div class='text_container'>
        <div class='tip_title'>会员服务为虚拟账号，支付成功后不支持退款！</div>
        <div class='tip'> 极速AlChat:3.5:</div>
        <div class='tip'> 1.生成一条内容消耗1个点：</div>
        <div class='tip' style='margin-bottom: 30px;'> 2.计时会员不消耗点数，按时间计算。</div>
        <div class='tip'>极速AlChat4.0:</div>
        <div class='tip'>1.生成一条内容消耗2个点；</div>
        <div class='tip'>2.4.0无时长会员，只能充值点数使用：</div>
        <div class='tip'>3.5和4.0点数卡不限使用时间：</div>
      </div>
      <div class='btn_'></div>
    </div>
    <div class='confirm_btn' @click="handlePay">￥{{ price }} <span>立即购买</span></div>
  </div>
</template>

<script>
import TopBar from '../../../components/TopBar'
import { getMyInfo, wechatPay } from 'api/MyResume'
import { Notify, Toast } from 'vant'

export default {
  name: 'index',
  components: {
    TopBar: TopBar
  },
  data () {
    return {
      active: 0,
      top_title: '',
      btns: [{
        text: '',
        functionName: ''
      }],
      price: 2.99,
      options: [],
      width: '500px',
      flag: 'package',
      optionsData: {
        package: [
          {
            text: '3天VIP',
            discountedPrice: 2.99,
            price: 2.99
          },
          {
            text: '一个月VIP',
            discountedPrice: 28,
            price: 49.90
          },
          {
            text: '三个月VIP',
            discountedPrice: 68,
            price: 128.00
          },
          {
            text: '一年VIP',
            discountedPrice: 129,
            price: 668.00
          }
        ],
        pointCards: [
          {
            text: '100点卡',
            discountedPrice: 19.8,
            price: 19.99
          },
          {
            text: '1000点卡',
            discountedPrice: 149,
            price: 249.90
          },
          {
            text: '10000点卡',
            discountedPrice: 850,
            price: 1280.00
          }
        ],
        signIn: [
          {
            text: '连续签到1天',
            discountedPrice: 19.8,
            price: 29.99
          },
          {
            text: '连续签到3天',
            discountedPrice: 149,
            price: 249.90
          },
          {
            text: '连续签到10天',
            discountedPrice: 850,
            price: 1280.00
          }
        ]
      },
      pay_data: {
        user_id: '',
        pay_type: '',
        days: 3,
        points: 100,
      },
      choose_data: '',

      head_img:'',
      nick_name:'',
      optimize_times:'',
      vip_exp_time:'',
      vip_is:0,
    }
  },
  mounted () {
    // this.getRouter()
    this.getInfo()
    this.options = [...this.optionsData.package]
    this.width = (110 + 15) * this.options.length - 15 + 'px'
  },
  methods: {
    choseOption (index,data) {
      this.active = index
      this.price = data.discountedPrice
      this.choose_data = data.text
    },
    choseTab (key) {
      this.options = [...this.optionsData[key]]
      this.width = (110 + 15) * this.options.length - 15 + 'px'
      this.flag = key
      if (key === 'package'){
        this.price = this.optionsData.package[0].price
      }else{
        this.price = this.optionsData.pointCards[0].price
      }
    },
    getRouter () {
      if (this.$route.query.click_type === 'bean') {
        this.flag = 'pointCards'
      }
    },
    pay_money () {
      this.pay_data.user_id = localStorage.getItem('id')
      this.pay_data.pay_type = this.flag
      if (this.pay_data.pay_type === 'package'){
        this.pay_data.points = 0
      }else if(this.pay_data.pay_type === 'pointCards'){
        this.pay_data.days = 0
      }
      if (this.choose_data === '3天VIP') {
        this.pay_data.days = 3
        this.pay_data.points = 0
      } else if (this.choose_data === '一个月VIP') {
        this.pay_data.days = 30
        this.pay_data.points = 0
      } else if (this.choose_data === '一年VIP') {
        this.pay_data.days = 365
        this.pay_data.points = 0
      } else if (this.choose_data === '100点卡') {
        this.pay_data.points = 100
        this.pay_data.days = 0
      } else if (this.choose_data === '1000点卡') {
        this.pay_data.points = 1000
        this.pay_data.days = 0
      } else if (this.choose_data === '10000点卡') {
        this.pay_data.points = 10000
        this.pay_data.days = 0
      }
      wechatPay(this.pay_data).then(resp => {
        WeixinJSBridge.invoke(
          "getBrandWCPayRequest",
          {
            appId: resp.appId, // 微信的appid
            timeStamp: resp.timeStamp, //时间戳
            nonceStr: resp.nonceStr, //随机串
            package: resp.package,  // 订单号
            signType: resp.signType, //微信签名方式：
            paySign: resp.paySign //微信签名
          },
          function(res) {
            if (res.err_msg == "get_brand_wcpay_request:ok") {
              // 使用以上方式判断前端返回,微信团队郑重提示：
              //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
              Notify({ type: 'success', message: '支付成功' });
            } else {
              Notify("支付失败，请重新支付");
            }
          }
        );

      }).catch(function (error) {
        Notify({//提示引用的是mint-UI里toast
          message: error
        })
      })
    },
    handlePay(){
      let that = this;
      if (typeof WeixinJSBridge == "undefined") {
        if (document.addEventListener) {
          document.addEventListener(
            "WeixinJSBridgeReady",
            that.pay_money,
            false
          );
        } else if (document.attachEvent) {
          document.attachEvent("WeixinJSBridgeReady", that.pay_money);
          document.attachEvent("onWeixinJSBridgeReady",that. pay_money);
        }
      } else {
        this.pay_money();
      }
    },
    getInfo(){
      getMyInfo(localStorage.getItem('id')).then((value) =>{
        this.head_img = value.data.headimgurl
        this.nick_name = value.data.nickname
        this.optimize_times = value.data.optimize_times
        this.vip_is = value.data.vip_is
        this.vip_exp_time = value.data.vip_exp_time
      })
    }
  }
}
</script>

<style scoped>
.top_word {
  position: absolute;
  font-size: 16px;
  left: 50%;
  top: 18px;
  margin-left: -75px;
  width: 150px;
  color: rgba(50, 82, 105, 1);
}

.confirm_btn {
  height: 47px;
  width: calc(100% - 40px);
  background-color: #0164FA;
  border-radius: 47px;
  line-height: 47px;
  position: fixed;
  left: 50%;
  bottom: 10px;
  transform: translate(-50%, 0);
  color: #FFFFFF;
  font-size: 16px;
  display: flex;
  justify-content: center;
  font-weight: 600;
}

.confirm_btn span {
  margin-left: 10px;
}

.btn_ {
  height: 60px;
  width: 100%;
}

.tip {
  font-size: 12px;
  color: #828282;
  margin-bottom: 10px;
}

.text_container {
  width: 100%;
  padding: 0 20px;
}

.tip_title {
  color: #4F4F4F;
  font-size: 12px;
  margin-bottom: 30px;
}

.options_top {
  font-size: 15px;
  color: #2A2E31;
  margin-bottom: 8px;
  font-weight: 500;
}

.options_mid span {
  font-size: 14px;
}

.options_mid {
  font-size: 24px;
  color: #0164FA;
  margin-bottom: 8px;
}

.options_bot {
  color: #BDBDBD;
  font-size: 12px;
  text-decoration: line-through;
}

.options_ {
  display: flex;
}

.active_options{
  width: 110px;
  height: 130px;
  border: 2px solid rgba(1, 100, 250, 1);
  border-radius: 8px;
  margin-right: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.active_options:last-child {
  margin-right: 0;
}

.options {
  width: 110px;
  height: 130px;
  border: 1px solid #D9D9D9;
  border-radius: 8px;
  margin-right: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.options:last-child {
  margin-right: 0;
}

.options_container {
  width: calc(100% - 30px);
  overflow-y: scroll;
  margin-top: 15px;
  margin-left: 15px;
  margin-right: 15px;
  margin-bottom: 30px;
}

.bottom_title_line {
  position: absolute;
  left: 0;
  bottom: 2px;
  width: 12px;
  height: 3px;
  border-radius: 3px;
  background-color: #0164FA;
}

.free_point {
  width: 7px;
  height: 7px;
  position: absolute;
  right: -10px;
  top: 7px;
  border-radius: 50%;
  background-color: #EA3F3F;
}

.bottom_title {
  font-size: 14px;
  color: #828282;
  height: 36px;
  line-height: 36px;
  position: relative;
}

.bottom_title_container {
  width: 100%;
  padding: 0 30px;
  display: flex;
  justify-content: space-around;
  padding-top: 5px;
}

.record_iocn {
  width: 16px;
  height: 16px;
  margin-right: 5px;
  background-image: url(../../../assets/images/icon/record.png);
  -webkit-background-size: 100% 100%;
  background-size: 100% 100%;
}

.userDate_record {
  height: 18px;
  padding: 0 10px;
  border-radius: 18px;
  border: 1px solid #00FFEB;
  display: flex;
  align-items: center;
}

.userDate {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #FFFFFF;
  font-size: 12px;
  font-weight: 500;
}

.userName {
  color: #FFFFFF;
  font-size: 15px;
  font-weight: 800;
  margin-bottom: 20px;
}

.avatar {
  width: 68px;
  height: 68px;
  border-radius: 50%;
  overflow: hidden;
  border: 2px solid #FFFFFF;
}

.info {
  width: calc(100% - 80px);
}

.userInfo {
  width: 100%;
  height: 100px;
  padding: 0 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.container_ {
  width: 100%;
  height: 100%;
}

.top {
  height: 200px;
  width: 100%;
  background-image: linear-gradient(to left, #80B7FE, #2FEDCB);
}

.bottom {
  width: 100%;
  height: calc(100% - 160px);
  background-color: rgba(255, 255, 255, 1);
  margin-top: -40px;
  border-radius: 20px 20px 0 0;
}

</style>
