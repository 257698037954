<template>
  <div class='container' style='padding-bottom: 0;'>
    <div class='page_header_interview'>
      <div class='page_header_title_interview' style='font-size: 20px; color: #2A2E31; font-weight: 700;'>面试助手</div>
      <div class='page_header_logo'></div>
      <div class="ask_tempelate">
        <div class="ask_header">
          <div class="header_left">
            <img src="../../assets/images/icon/ask_template.png" alt="">
            <div class="header_left_word" style="margin-left: 5px">提问模板</div>
          </div>
          <div class="header_right" @click="more_template">更多
            <van-icon name="arrow"/>
          </div>
        </div>
        <div class="ask_blocks">
          <div class="ask_block block1_bg" @click="ask_template('我希望你是一个资深的的人力资源专家, 能对我的面试问题提出建议：如何回答如下问题')">
            <div class="block_title">面试问题</div>
            <div class="block_intro">输入你的面试问题，面试专家会为你提供问题回答的建议</div>
          </div>
          <div class="ask_block block2_bg" @click="ask_template('我希望你是一个资深的技术专家, 能帮助我回答如下的面试技术问题')">
            <div class="block_title">技术问题</div>
            <div class="block_intro">输入的面试中的技术问题，技术专家会给你的面试问题提供答案</div>
          </div>
          <div class="ask_block block3_bg" @click="ask_template('我希望你是一个智力问题专家, 能帮助我回答如下的面试中的智力问题，提供答案')">
            <div class="block_title">智力问题</div>
            <div class="block_intro">请输入面试中的智力问题，人工智能为你提供问题的答案</div>
          </div>
<!--          <div class="ask_block block4_bg" @click="ask_template('群面技巧')">-->
<!--            <div class="block_title">群面技巧</div>-->
<!--            <div class="block_intro">群面到底该如何进行呢？</div>-->
<!--          </div>-->
        </div>
      </div>
    </div>
    <div class='hot_question'>
      <div class="ask_header">
        <div class="header_left">
          <img src="../../assets/images/icon/fire.png" alt="">
          <div class="header_left_word" style="margin-left: 5px">热门提问</div>
        </div>
        <div class="header_right" @click="checkQuestions">换一换
          <van-icon name="replay"/>
        </div>
      </div>
      <div class="hot_question_content">
        <div class="content_line" @click="copyQuestion(item)" v-for='(item,index) of hot_questions.slice(0,3)' :key='index'><p>{{ item }}</p></div>
      </div>
      <div class="ask_ai">
        <div class="ask_input" @click="toAiChat()">
          <div class="ask_remind">{{ask_input}}</div>
          <img src="../../assets/images/send.png" alt="">
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { wechatPay } from 'api/MyResume'

export default {
  name: 'index',
  components: {},
  data () {
    return {
      ask_input:"您可以直接输入问题和AI交流",
      hot_questions: [
        '您为什么要换目前的工作？',
        '您遇到过的最具挑战性和压力最大的情况是什么，如何应对？',
        '您因努力而失败。您是如何处理失败的？',
        '您认为您对这份工作不满意。您如何解决？',
        '如何回答“请介绍你自己”。',
        '为什么你希望来我们公司工作？',
        '您觉得这个职位最吸引你的地方是什么？最不吸引你的地方又是什么？',
        '谈论一下你对你应聘的这个职位的理解。',
        '您为什么觉得你适合我们这个岗位？',
        '您对自己未来的规划是怎样的？',
        '您希望这个职位的薪水是多少？',
        '您还有什么问题要问吗？',
        '谈谈你过去做过的成功案例？',
      ]
    }
  },
  mounted () {
  },
  methods: {
    copyQuestion(data){
      this.ask_input = data
    },
    checkQuestions(){
      let stack = [];
      while (this.hot_questions.length) {
        //Math.random()：返回 [0,1) 之间的一个随机数
        let index = parseInt(Math.random() * this.hot_questions.length);  // 利用数组长度生成随机索引值
        stack.push(this.hot_questions[index]);  // 将随机索引对应的数组元素添加到新的数组中
        this.hot_questions.splice(index, 1);  // 删除原数组中随机生成的元素
      }
      this.hot_questions = stack
    },
    toWechatPay () {
      wechatPay().then(resp => {
        console.log(resp)
      }).catch(error => {
        console.log(error)
      })
    },
    toAiChat () {
      if (this.ask_input === '您可以直接输入问题和AI交流'){
        this.ask_input = ''
      }
      this.$router.push({
        path: 'interview_chat',
        query: {
          question:this.ask_input
        }
      })
    },
    ask_template (data) {
      this.$router.push({
        path: 'interview_template',
        query: {
          question:data
        }
      })
    },
    more_template () {
      this.$router.push({
        path: 'interview_template_list',
        query: {}
      })
    }
  }
}

</script>

<style scoped>
.ask_ai {
  margin-top: 20%;
  margin-bottom: 10%;
}

.ask_input img {
  width: 35px;
}

.ask_input {
  width: 100%;
  height: 40px;
  border: 1px solid rgba(234, 234, 234, 1);
  border-radius: 10px;
  background-color: rgba(252, 252, 252, 1);
  color: rgba(151, 151, 151, 1);
  padding-left: 15px;
  padding-right: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.content_line {
  line-height: 30px;
  margin-top: 5px;
  color: rgba(111, 168, 255, 1);
  padding-left: 15px;
  width: 100%;
  height: 30px;
  background-color: rgba(249, 251, 255, 1);
}
.content_line p{
  width:100%;
  overflow: hidden;  /*超出部分隐藏*/
  white-space: nowrap;  /*禁止换行*/
  text-overflow: ellipsis; /*省略号*/
}

.hot_question {
  margin-top: 20px;
  padding: 0 15px;
}

.block_intro {
  margin-top: 10px;
  line-height: 18px;
  font-size: 12px;
  color: rgba(130, 130, 130, 1);
}

.block_title {
  font-size: 15px;
  color: rgba(42, 46, 49, 1);
}

.block1_bg {
  background-image: url(../../assets/images/block1.png);
  background-size: 110% 110%;
  background-position-x: 1%;
}

.block2_bg {
  background-image: url(../../assets/images/block2.png);
  background-size: 110% 110%;
  background-position-x: 1%;
}

.block3_bg {
  background-image: url(../../assets/images/block3.png);
  background-size: 110% 110%;
  background-position-x: 1%;
}

.block4_bg {
  background-image: url(../../assets/images/block4.png);
  background-size: 100% 100%;
}

.ask_blocks {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.ask_block {
  padding: 15px;
  padding-right: 10px;
  margin-top: 10px;
  width: 169px;
  height: 100px;
  border-radius: 13px;
  box-shadow: 0px 4px 4px 0px rgba(188, 215, 255, 0.25);
}

.header_right {
  margin-top: 2px;
  font-size: 14px;
  color: rgba(112, 112, 112, 1);
  line-height: 14px;
}

.ask_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header_left img {
  width: 16px;
}

.header_left {
  display: flex;
  align-items: center;
  font-size: 15px;
  margin-top: 5px;
}

.page_header_interview {
  padding: 0 15px;
  width: 100%;
  background: linear-gradient(180deg, rgba(1, 100, 250, 0.08) 0%, rgba(1, 100, 250, 0.35) 0.01%, rgba(1, 100, 250, 0) 100%);
  z-index: 999;
}

.page_header_title_interview {
  height: 50px;
  line-height: 50px;
  font-size: 20px;
  color: #2A2E31;
  font-weight: bold;
}

.container {
  height: 100%;
  overflow: auto;
}

.container_ {
  height: calc(100% - 180px);
  /*background-color: #F2F2F2;*/
  border-radius: 20px 20px 0 0;
  padding: 0 15px;
}
</style>
