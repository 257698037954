<template>
  <div class='container' style='padding-bottom: 0;'>
    <TopBar :top_title='top_title' :btns='btns' :hiddenNotice='true'></TopBar>
    <div class='container_'>
      <div class="header_introduce">
        {{ this.notice_word }}
      </div>
      <div class='hot_question'>
        <div class="ask_header">
          <div class="header_left">
            <img src="../../assets/images/T_file.png" alt="">
            <div class="header_left_word" style="margin-left: 5px">常见问题</div>
          </div>
          <div class="header_right" @click="checkQuestions">换一换
            <van-icon name="replay"/>
          </div>
        </div>
        <div class="hot_question_content">
          <div class="content_line" @click="copyQuestion(item)" v-for='(item,index) of hot_questions.slice(0,3)' :key='index'><p>{{ item }}</p></div>
        </div>
        <div class="ask_area" id="ask_area_">
          <div class="chatwindow">
            <div class="chat_content" id="chat_window">
              <div
                v-for="(item, index) of chat_list"
                :key="item.id"
              >
                <div class="ask_bubble" v-if="item.type == 'ask'">
                  <div class="head_img"></div>
                  <div class="chat_ask" v-if="item.content != ''">
                    <vuetyped :strings="[item.content]" :loop="false" :smart-backspace="true" :showCursor="false">
                      <div class="typing" />
                    </vuetyped>
                  </div>
                  <div class="loading_status" v-if="item.content === ''" style="font-size: 26px;margin-left: 10px">
                    <van-loading type="spinner" />
                  </div>
                </div>
                <div class="answer_bubble" v-if="item.type == 'answer'">
                  <div class="chat_answer">{{ item.content }}</div>
                  <div class="user_head_img" :style="{'background-image':'url(' + imagePath + ')'}"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="ask_ai">
          <div class="ask_input">
            <div class="ask_remind">
              <input type="text" v-model="chat_msg" :disabled="chat_disable == 1"
                     :placeholder="chat_disable === 1 ? '暂时不可输入...' : '请输入...'">
            </div>
            <img class="send_img" v-if="chatflag === true" @click="sendMsg" src="../../assets/images/send.png" alt="">
            <img class="send_img" v-if="chatflag === false" @click="sendMsgTwice" src="../../assets/images/send.png" alt="">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TopBar from '../../components/TopBar'
import { Notify } from 'vant'
import store from '@/store'
import { InterViewChat } from 'api/MyResume'

export default {
  name: 'Login',
  components: {
    TopBar: TopBar,
  },
  data () {
    return {
      top_title: '提问模板',
      userInfo: {
        avatar: ''
      },
      CropperFlag: false,
      imagePath: store.state.userInfo.headimgurl,
      btns: [{
        text: '',
        functionName: ''
      }],
      hot_questions: [
        '您为什么要换目前的工作？',
        '您遇到过的最具挑战性和压力最大的情况是什么，如何应对？',
        '您因努力而失败。您是如何处理失败的？',
        '您认为您对这份工作不满意。您如何解决？',
        '如何回答“请介绍你自己”。',
        '为什么你希望来我们公司工作？',
        '你觉得这个职位最吸引你的地方是什么？最不吸引你的地方又是什么？',
        '谈论一下你对你应聘的这个职位的理解',
        '你为什么觉得你适合我们这个岗位？',
        '你对自己未来的规划是怎样的？',
        '你希望这个职位的薪水是多少？',
        '你还有什么问题要问吗？',
        '谈谈你过去做过的成功案例？',
      ],
      userid: store.state.userInfo.id,
      conversation_id: '',
      chat_disable: 0,
      chat_msg: '',
      notice_word:'',
      chat_list: [],
      chatstatus: false,
      chatflag: true
    }
  },
  methods: {
    sendMsgTwice(){
      Notify("请稍等")
    },
    copyQuestion(data){
      this.chat_msg = data
    },
    checkQuestions(){
      let stack = [];
      while (this.hot_questions.length) {
        //Math.random()：返回 [0,1) 之间的一个随机数
        let index = parseInt(Math.random() * this.hot_questions.length);  // 利用数组长度生成随机索引值
        stack.push(this.hot_questions[index]);  // 将随机索引对应的数组元素添加到新的数组中
        this.hot_questions.splice(index, 1);  // 删除原数组中随机生成的元素
      }
      this.hot_questions = stack
    },
    async getMsg () {
      this.chatflag = false
      this.chat_disable = 1
      InterViewChat(this.conversation_id,this.chat_msg,localStorage.getItem('id')).then(resp => {
        if (resp.status === 'success' && resp.data){
          this.chat_list.push({
            type: 'ask',
            content: resp.data
          })
          this.chat_list = this.chat_list.filter(t => t.content !== '')
          this.$nextTick(() => {
            this.toBottom()
          })
          this.chat_msg = ''
          this.chatflag = true
          this.chat_disable = 0
        }else{
          Notify(resp.msg)
        }
      }).catch(error => {
        Notify("服务器繁忙")
      })
    },
    sendMsg () {
      if (this.chat_msg === '') {
        Notify('输入为空!!')
      } else {
        this.chat_list.push({
          type: 'answer',
          content: this.chat_msg
        })
        this.chat_list.push({
          type: 'ask',
          content: ''
        })
        this.$nextTick(() => {
          this.toBottom()
        })
        this.chatflag = false
        this.chat_disable = 1
        this.getMsg()
      }
    },
    async ChatStatus () {
      this.chatflag = false
      this.chat_disable = 1
      if (this.conversation_id == '') {
        this.chat_list.push({
          type: 'ask',
          content: ''
        })
        InterViewChat(this.conversation_id,this.notice_word,localStorage.getItem('id')).then(resp => {
          if (resp.status === 'success' && resp.data){
            this.chat_list.push({
              type: 'ask',
              content: resp.data
            })
            this.chat_list = this.chat_list.filter(t => t.content !== '')
            this.$nextTick(() => {
              this.toBottom()
            })
            this.conversation_id = resp.conversation_id
            this.chat_msg = ''
            this.chatflag = true
            this.chat_disable = 0
          }else{
            Notify(resp.msg)
          }
        }).catch(error => {
          Notify("服务器繁忙")
        })
      }
    },
    toBottom () {
      this.$nextTick(() => {
        var scrollDom = document.getElementById('ask_area_')
        scrollDom.scrollTop = scrollDom.scrollHeight
      })
    },
    getQuestion(){
      this.notice_word = this.$route.query.question
      this.ChatStatus()
    },
  },
  mounted () {
    this.getQuestion()
  },
  watch: {
    chat_list: function (){
      this.$nextTick(() => {
        var scrollDom = document.getElementById('chat_window')
        scrollDom.scrollTop = scrollDom.scrollHeight
      })
    }
  }
}
</script>

<style scoped>
.ask_input input {
  outline: none;
  width: 260px;
  height: 24px;
  border: none;
}
.ask_area{
  margin-top: 10px;
  width: 100%;
  height: 322px;
  border: 1px solid rgba(235, 235, 235, 1);
  overflow: auto;
}
.chat_answer {
  padding: 15px;
  min-height: 20px;
  max-width: 60%;
  background: #0A41DE;
  border-radius: 20px 0px 20px 20px;

  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #FFFFFF;
  margin: 10px 15px 5px 0px;
}

.user_head_img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  /*background-image: url("../../assets/images/user_head.png");*/
  background-repeat: no-repeat;
  background-size: cover;
}

.answer_bubble {
  display: flex;
  /*align-items: center;*/
  justify-content: flex-end;
}

.chat_ask {
  max-width: 60%;
  padding: 10px;
  margin-left: 15px;
  min-height: 20px;
  background: rgba(245, 249, 250, 1);
  border-radius: 0px 18px 18px 18px;

  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: rgba(120, 120, 120, 1);
  line-height: 20px;
}

.mask {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #0b0b0b;
  width: 100%;
  height: 100%;
  z-index: 99999;
  opacity: .7;
}

.ask_bubble {
  display: flex;
  /*align-items: center;*/
}

.head_img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-image: url("../../assets/images/manage_head.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.send_img {
  width: 50px;
}

.chat_input {
  border-radius: 20px 20px 0px 0px;
  background-color: rgba(242, 242, 242, 1);
  padding: 15px;
  width: 100%;
  position: fixed;
  bottom: 0;
  display: flex;
}

.chat_input button {
  width: 134px;
  height: 50px;
  background: #FFFFFF;
  border: 1px solid #0A41DE;
  border-radius: 25px;
  color: #0A41DE;
}

.chat_input input {
  width: 100%;
  height: 40px;
  background: #FFFFFF;
  border: 1px solid #E3E5EC;
  border-radius: 10px;
  outline: none;
  font-size: 16px;
  padding-left: 20px;
  color: #C4C8D3;
}

.chat_but {
  position: relative;
  width: 100%;
  height: 80px;
  background: #FFFFFF;
  border-radius: 0px 0px 18px 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 80px;
}

.chat_content {
  padding: 5px;
  width: 100%;
  /*background: #EBF0FD;*/
  overflow: auto;
  white-space: pre-wrap;
}

.chat_header {
  width: 100%;
  height: 60px;
  background: #FFFFFF;
  border-radius: 18px 18px 0px 0px;
  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #222222;
  text-align: center;
  line-height: 60px;
}

.chatwindow {
  width: 100%;
  height: 180px;
  background: #FFFFFF;
  border-radius: 18px;
  z-index: 999991;
}

.header_right {
  margin-top: 2px;
  font-size: 14px;
  color: rgba(112, 112, 112, 1);
  line-height: 14px;
}

.ask_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header_left img {
  width: 16px;
}

.header_left {
  display: flex;
  align-items: center;
  font-size: 15px;
  margin-top: 5px;
}
.ask_ai {
  margin-top: 20px;
}

.ask_input img {
  width: 35px;
}

.ask_input {
  width: 100%;
  height: 40px;
  border: 1px solid rgba(234, 234, 234, 1);
  border-radius: 10px;
  background-color: rgba(252, 252, 252, 1);
  color: rgba(151, 151, 151, 1);
  padding-left: 15px;
  padding-right: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.content_line {
  line-height: 30px;
  margin-top: 5px;
  color: rgba(111, 168, 255, 1);
  padding-left: 15px;
  width: 100%;
  height: 30px;
  background-color: rgba(249, 251, 255, 1);
}
.content_line p{
  width:100%;
  overflow: hidden;  /*超出部分隐藏*/
  white-space: nowrap;  /*禁止换行*/
  text-overflow: ellipsis; /*省略号*/
}

.hot_question {
  margin-top: 20px;
}
.header_introduce{
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0px;
  color: rgba(79, 79, 79, 1);
}

.container_ {
  background-color: #fff;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 15px;
  padding-top: 50px;

}
</style>
