/**
 *  @author
 * @description  接口封装
*/
import axios from 'axios'
import { baseApi } from './../config'
import router from '@/router'
import { Notify } from 'vant'

const service = axios.create({
  baseURL: baseApi,
  withCredentials: false,
  timeout: 300000
})

// request拦截器 request interceptor
service.interceptors.request.use(
  config => {
    const id = localStorage.getItem('id')
    const token = localStorage.getItem('access_token')

    // const id = '0d0eb9fe-ae55-43bb-b600-8e8ab51f8ec5'
    // const token = '71_zaho0F513UJRs8CndEZtV0tU8PE5Q-6yIYIralFGJvZnTEvWSrN0wlq_AX_xHBe36e5Itz_DfmnSIyfFjZ8fG6tCPaWiqGFpSt9IfrYLwiI'

    if (token && id) {
      config.headers.Authorization = token;
      config.headers.Userid = id;
    } else {
      // if(router.currentRoute.fullPath !== '/login'){
      //   router.replace({
      //     path: '/login'
      //   });
      // }
    }
    return config;
  },
  err => {
    return Promise.reject(err);
  }
);


// respone拦截器
service.interceptors.response.use(response => {
  const res = response.data
  if(res.status && res.status !== 'success') {
    return Promise.reject(res)
  } else {
    return Promise.resolve(res)
  }
},error =>{
  return Promise.reject(error)
})
export default service
