import { ref, reactive, watch, nextTick } from 'vue';
import TabBar from './../components/TabBar/TabBar';
import { useStore } from 'vuex';
export default {
  name: 'AppLayout',
  components: {
    TabBar: TabBar
  },
  setup: function setup() {
    var $store = useStore();
    var state = reactive({
      tabBar: [{
        title: '简历诊断',
        to: {
          name: 'resume_optimize'
        },
        icon: 'icon1',
        icon2: 'icon1_'
      }, {
        title: '面试助手',
        to: {
          name: 'interview'
        },
        icon: 'icon2',
        icon2: 'icon2_'
      }, {
        title: '我的简历',
        to: {
          name: 'mycv'
        },
        icon: 'icon3',
        icon2: 'icon3_'
      }, {
        title: '用户中心',
        to: {
          name: 'user_center'
        },
        icon: 'icon4',
        icon2: 'icon4_'
      }]
    });
    var showFooter = $store.state.showFooter;

    var handleChange = function handleChange(value) {};

    return {
      showFooter: showFooter,
      state: state,
      handleChange: handleChange
    };
  }
};