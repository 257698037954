import { ref, reactive, onMounted } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import axios from 'axios';
export default {
  name: 'OptimizeIndex',
  components: {},
  setup: function setup() {
    var $store = useStore();
    var router = useRouter();
    var scrollText = $store.state.scrollText;
    var state = reactive({
      active: 0,
      file_name: ''
    });

    var BackResume = function BackResume() {
      router.go(-1);
    };

    var downLoad = function downLoad() {};

    onMounted(function () {
      state.notice_text = $store.state.scrollText;
    });
    return {
      state: state,
      scrollText: scrollText,
      BackResume: BackResume,
      downLoad: downLoad
    };
  }
};