import "core-js/modules/es.array.find-index";
import "core-js/modules/es.function.name";
import "core-js/modules/es.regexp.exec";
import "core-js/modules/es.string.split";
import _createForOfIteratorHelper from "D:/\u7B80\u5386GPT/resumechatgpt/node_modules/@babel/runtime/helpers/esm/createForOfIteratorHelper";
import _objectSpread from "D:/\u7B80\u5386GPT/resumechatgpt/node_modules/@babel/runtime/helpers/esm/objectSpread2";
import "vant/es/toast/style";
import _Toast from "vant/es/toast";
import "vant/es/dialog/style";
import _Dialog from "vant/es/dialog";
import store from '../../../store';
import TopBar from '../../../components/TopBar';
import { formatTime_YMD } from '../../../util/index';
import { localOptimizationResume as _localOptimizationResume } from '../../../api/MyResume';
export default {
  //其它经历
  name: 'Other',
  components: {
    TopBar: TopBar
  },
  data: function data() {
    return {
      currentDate: new Date(),
      currentDate2: new Date(),
      checked: '',
      index: '',
      show: false,
      AiMessage: '',
      optimization_idea: '',
      showCalendar: false,
      showAiContent: false,
      showCalendar2: false,
      name: '',
      info: {
        key1: '',
        key2: '',
        key2_: '',
        key3: '',
        key4: '',
        key5: '',
        key6: ''
      },
      top_title: '新增经历',
      btns: [{
        text: '确认',
        functionName: 'saveInfo'
      }],
      flag: '' //true 编辑   false  新增

    };
  },
  mounted: function mounted() {
    this.show = !!(this.$route.query.flag && this.$route.query.flag === 'addModule');
    this.index = this.$route.query.index;
    this.flag = !!this.index;
    this.top_title = this.$route.query.type;

    if (this.flag) {
      this.getInfo(this.$route.query.type);
    }
  },
  methods: {
    deleteExperience: function deleteExperience() {
      var _this = this;

      _Dialog.confirm({
        title: '提示：',
        message: "\u662F\u5426\u5220\u9664\u8BE5\u6761".concat(this.top_title, "\uFF1F")
      }).then(function () {
        var data = {
          type: _this.top_title,
          index: _this.index
        };

        _this.$store.commit('deleteExperienced', data);

        _this.$router.go(-1);

        _Toast.success('删除成功！');
      }).catch(function () {// on cancel
      });
    },
    startOptimizing: function startOptimizing() {
      if (this.info.key6 !== '') {
        this.showAiContent = true;
        this.localOptimizationResume();
      } else {
        _Toast.fail('请填写内容再进行优化！');
      }
    },
    localOptimizationResume: function localOptimizationResume() {
      var that = this;

      var info = _objectSpread({}, this.info);

      info.key2 = info.key2 + '~' + info.key2_;
      delete info.key2_;
      that.AiMessage = '';
      var data = {
        'resume_id': this.$route.query.id,
        //简历id
        'conversation_id': store.state.conversation_id,
        //会话id,首次会话提交空的字符串，根据返回的 会话id，开始下一段会话。
        'order': '让内容看起来更专业更丰富',
        //用户提出的优化指令
        'ask_str': JSON.stringify(info) //需要优化的内容字符串

      };

      _localOptimizationResume(data).then(function (res) {
        var resdata = JSON.parse(res.data);
        that.optimization_idea = resdata.optimization_idea;
        that.info.key1 = resdata.optimization_result.key1;
        that.info.key3 = resdata.optimization_result.key3;
        that.info.key4 = resdata.optimization_result.key4;
        that.info.key5 = resdata.optimization_result.key5;
        that.AiMessage = resdata.optimization_result.key6;
        var arr = resdata.optimization_result.key2.split('~');
        that.info.key2 = arr[0];

        if (arr.length > 1) {
          that.info.key2_ = arr[1];
        }

        that.$store.commit('saveConversationId', res.conversation_id);
      }).catch(function (err) {
        that.localOptimizationResume();
      });
    },
    coverMessage: function coverMessage() {
      if (this.AiMessage) {
        this.info.key6 = this.AiMessage;
      }
    },
    insetMessage: function insetMessage() {
      if (this.AiMessage) {
        this.info.key6 = this.info.key6 + this.AiMessage;
      }
    },
    getInfo: function getInfo(type) {
      var _iterator = _createForOfIteratorHelper(store.state.resumeInfo.experienced),
          _step;

      try {
        for (_iterator.s(); !(_step = _iterator.n()).done;) {
          var item = _step.value;

          if (item.name === type) {
            var data = item.content[this.index];
            this.info = _objectSpread({}, data);
            var arr = data.key2.split('~');
            this.info.key2 = arr[0];

            if (arr.length > 1) {
              this.info.key2_ = arr[1];
            }
          }
        }
      } catch (err) {
        _iterator.e(err);
      } finally {
        _iterator.f();
      }
    },
    onSubmit: function onSubmit(val) {},
    onConfirm: function onConfirm(date) {
      this.info.key2 = formatTime_YMD(date);
      this.showCalendar = false;
    },
    onConfirm2: function onConfirm2(date) {
      var day = new Date();
      var str = formatTime_YMD(day);
      var formatStr = formatTime_YMD(date);
      this.info.key2_ = formatTime_YMD(date);

      if (str === formatStr) {
        this.info.key2_ = '至今';
      } else {
        this.info.key2_ = formatStr;
      }

      this.showCalendar2 = false;
    },
    saveInfo: function saveInfo() {
      var _this2 = this;

      var info = _objectSpread({}, this.info);

      info.key2 = info.key2 + '~' + info.key2_;
      delete info.key2_;

      if (this.flag) {
        var data = {
          type: this.top_title,
          index: this.index,
          data: info
        };
        this.$store.commit('saveExperienced', data);
      } else {
        if (this.show) {
          var count = store.state.resumeInfo.experienced.length + 2;

          if (store.state.resumeInfo.individualWorks.length > 0) {
            count = count + 1;
          }

          if (store.state.resumeInfo.expertise.length > 0) {
            count = count + 1;
          }

          if (store.state.resumeInfo.languageSkills.length > 0) {
            count = count + 1;
          }

          var i = store.state.resumeInfo.experienced.findIndex(function (d) {
            return d.name === _this2.name;
          });

          if (i > -1) {
            var _data = {
              type: this.name,
              index: count + 1,
              data: info
            };
            this.$store.commit('addExperienced', _data);
          } else {
            var _data2 = {
              name: this.name,
              index: count + 1,
              content: [info]
            };
            this.$store.commit('addExperiencedMoudle', _data2);
          }
        } else {
          var _data3 = {
            type: this.top_title,
            index: this.index,
            data: info
          };
          this.$store.commit('addExperienced', _data3);
        }
      }

      this.$router.go(-1);
    }
  }
};