import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-4cddb896"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = {
  class: "container"
};
var _hoisted_2 = {
  class: "resume_con_bg"
};
var _hoisted_3 = {
  class: "resume_con"
};
var _hoisted_4 = ["innerHTML"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_TopBar = _resolveComponent("TopBar");

  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_TopBar, {
    top_title: $data.top_title,
    btns: $data.btns,
    "hidden-notice": true
  }, null, 8, ["top_title", "btns"]), _createElementVNode("div", _hoisted_2, [_createElementVNode("div", {
    class: "resume_con_",
    style: _normalizeStyle({
      height: $data.height + "px"
    })
  }, [_createElementVNode("div", _hoisted_3, [$data.html ? (_openBlock(), _createElementBlock("div", {
    key: 0,
    class: "A4_container",
    style: _normalizeStyle({
      transform: "scale(".concat($data.scale, ")"),
      height: $data.height_ + "px"
    })
  }, [_createElementVNode("div", {
    innerHTML: $data.html
  }, null, 8, _hoisted_4)], 4)) : _createCommentVNode("", true)])], 4)]), _createElementVNode("div", {
    class: "change_btn",
    onClick: _cache[0] || (_cache[0] = function ($event) {
      return $options.goto("resume_template");
    })
  }, "更换模板")]);
}