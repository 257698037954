<template>
  <div class='container' style='padding-bottom: 0;'>
    <TopBar :top_title='top_title' :btns='btns' :hiddenNotice='true'></TopBar>
    <div class='container_'>
      <div class="ask_block">
        <div class="ask_block_title">
          <img src="../../assets/images/recentage.png" alt="">
          模拟面试
        </div>
        <div class="ask_blocks">
          <div class="ask_pink_block" @click="toAiChat('下面我将输入岗位名称，请根据我输入的岗位名称来进行模拟面试')">
            <div class="block_title">按应聘岗位</div>
            <div class="block_intro">请输入岗位名称，输入“开始模拟面试”后，开始进行模拟面试。</div>
          </div>
          <div class="ask_pink_block" @click="toAiChat('下面我将输入应聘行业，请根据我输入的应聘行业来进行模拟面试')">
            <div class="block_title">按照应聘行业</div>
            <div class="block_intro">请输入面试的行业名称，输入“开始模拟面试”后，开始进行模拟面试。</div>
          </div>
          <div class="ask_pink_block" @click="toAiChat('下面我将输入应聘企业，请根据我输入的应聘企业来进行模拟面试')">
            <div class="block_title">按照应聘企业</div>
            <div class="block_intro">请输入面试的企业名称，输入“开始模拟面试”后，开始进行模拟面试。</div>
          </div>
        </div>
      </div>
      <div class="ask_block">
        <div class="ask_block_title">
          <img src="../../assets/images/recentage.png" alt="">
          职业规划
        </div>
        <div class="ask_blocks">
          <div class="ask_pink_block" @click="toAiChat('请回答一些精选常见面试问提')">
            <div class="block_title">职业规划</div>
            <div class="block_intro">精选常见面试问提</div>
          </div>
          <div class="ask_pink_block" @click="toAiChat('根据简历和求职岗位，生成引人注目的自我介绍')">
            <div class="block_title">自我介绍</div>
            <div class="block_intro">根据简历和求职岗位，生成引人注目的自我介绍</div>
          </div>
          <div class="ask_pink_block">
            <div class="block_title" @click="toAiChat('根据简历和求职岗位，生成个性化求职信')">个性化求职信</div>
            <div class="block_intro">简历设递时，给招聘人员打个招呼，留下好的印象吧</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TopBar from '../../components/TopBar'
import { Notify } from 'vant'
import store from '@/store'

export default {
  name: 'Login',
  components: {
    TopBar: TopBar,
  },
  data () {
    return {
      top_title: '提问模板',
      userInfo: {
        avatar: ''
      },
      CropperFlag: false,
      imagePath: 'https://fastly.jsdelivr.net/npm/@vant/assets/cat.jpeg',
      btns: [{
        text: '',
        functionName: ''
      }],
    }
  },
  methods: {
    toAiChat(data){
      this.$router.push({
        path: 'interview_chat',
        query: {
          question:data
        }
      })
    }
  }
}
</script>

<style scoped>
.block_intro {
  margin-top: 14px;
  line-height: 16px;
  font-size: 12px;
  color: rgba(130, 130, 130, 1);
}

.block_title {
  font-size: 15px;
  color: rgba(42, 46, 49, 1);
}

.ask_blocks{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.ask_pink_block{
  padding: 15px;
  padding-right: 10px;
  background-image: url("../../assets/images/pink_bg.png");
  width: 169px;
  height: 100px;
  border-radius: 13px;
  margin-top: 10px;
  /*border: 1px solid rgba(255, 245, 232, 1)*/
}
.ask_block_title img {
  margin-right: 9px;
  width: 4px;
}
.ask_block_title{
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.container_ {
  background-color: #fff;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 15px;
  padding-top: 50px;

}
</style>
