import "core-js/modules/es.array.find-index";
import "core-js/modules/es.array.join";
import "core-js/modules/es.array.slice";
import "core-js/modules/es.array.splice";
import "core-js/modules/es.function.name";
import "core-js/modules/es.number.constructor";
import "core-js/modules/es.regexp.exec";
import "core-js/modules/es.string.replace";
import "core-js/modules/es.string.split";
import _objectSpread from "D:/\u7B80\u5386GPT/resumechatgpt/node_modules/@babel/runtime/helpers/esm/objectSpread2";
import _createForOfIteratorHelper from "D:/\u7B80\u5386GPT/resumechatgpt/node_modules/@babel/runtime/helpers/esm/createForOfIteratorHelper";
import { createStore } from 'vuex';
export default createStore({
  state: {
    userInfo: {
      id: '',
      headimgurl: 'https://fastly.jsdelivr.net/npm/@vant/assets/cat.jpeg',
      nickname: '',
      sex: 0
    },
    filePath: null,
    fileName: '',
    fileId: '',
    scrollText: '忙象 "优" 简历上线啦!',
    resumeInfo: null,
    showFooter: true,
    ResumeScore: '',
    Suggestion: [],
    isEdit: false,
    conversation_id: '',
    backIndex: false,
    staticPath: 'http://files.haijigis.com:17371/static/',
    toDownLoad: false
  },
  mutations: {
    getUserInfo: function getUserInfo(state, data) {
      state.userInfo = data;
    },
    getResumeInfo: function getResumeInfo(state, data) {
      var obj = {
        baseInfo: {},
        experienced: [],
        languageSkills: [],
        languageSkillsIndex: '',
        individualWorks: [],
        individualWorksIndex: '',
        expertise: [],
        expertiseIndex: ''
      };

      var _iterator = _createForOfIteratorHelper(data),
          _step;

      try {
        for (_iterator.s(); !(_step = _iterator.n()).done;) {
          var item = _step.value;
          item.index = Number(item.index);

          if (item.name === '基本信息') {
            obj.baseInfo = item.content;
          } else if (item.name === '个人简介') {
            var str = '';

            var _iterator2 = _createForOfIteratorHelper(item.content),
                _step2;

            try {
              for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
                var s = _step2.value;
                str = str + s;
              }
            } catch (err) {
              _iterator2.e(err);
            } finally {
              _iterator2.f();
            }

            obj.introduction = str;
          } else if (item.name === '简历评分') {
            state.ResumeScore = item.content[0].replace(/分/g, '');
          } else if (item.name === '语言能力') {
            obj.languageSkills = item.content;
            obj.languageSkillsIndex = item.index;
          } else if (item.name === '专业技能') {
            obj.expertise = item.content;
            obj.expertiseIndex = item.index;
          } else if (item.name === '个人作品') {
            obj.individualWorks = item.content;
            obj.individualWorksIndex = item.index;
          } else {
            obj.experienced.push(item);
          }
        }
      } catch (err) {
        _iterator.e(err);
      } finally {
        _iterator.f();
      }

      state.resumeInfo = obj;
    },
    saveBaseInfo: function saveBaseInfo(state, data) {
      state.resumeInfo.baseInfo = data;
      state.isEdit = true;
    },
    saveIntroduction: function saveIntroduction(state, data) {
      state.resumeInfo.introduction = data;
      state.isEdit = true;
    },
    saveExperienced: function saveExperienced(state, data) {
      var _iterator3 = _createForOfIteratorHelper(state.resumeInfo.experienced),
          _step3;

      try {
        for (_iterator3.s(); !(_step3 = _iterator3.n()).done;) {
          var item = _step3.value;

          if (item.name === data.type) {
            item.content[data.index] = _objectSpread({}, data.data);
            state.isEdit = true;
          }
        }
      } catch (err) {
        _iterator3.e(err);
      } finally {
        _iterator3.f();
      }
    },
    addExperienced: function addExperienced(state, data) {
      var _iterator4 = _createForOfIteratorHelper(state.resumeInfo.experienced),
          _step4;

      try {
        for (_iterator4.s(); !(_step4 = _iterator4.n()).done;) {
          var item = _step4.value;

          if (item.name === data.type) {
            item.content.push(_objectSpread({}, data.data));
            state.isEdit = true;
          }
        }
      } catch (err) {
        _iterator4.e(err);
      } finally {
        _iterator4.f();
      }
    },
    deleteExperienced: function deleteExperienced(state, data) {
      var _iterator5 = _createForOfIteratorHelper(state.resumeInfo.experienced),
          _step5;

      try {
        for (_iterator5.s(); !(_step5 = _iterator5.n()).done;) {
          var item = _step5.value;

          if (item.name === data.type) {
            item.content.splice(data.index, 1);
            state.isEdit = true;
          }
        }
      } catch (err) {
        _iterator5.e(err);
      } finally {
        _iterator5.f();
      }
    },
    addExperiencedMoudle: function addExperiencedMoudle(state, data) {
      state.isEdit = true;
      state.resumeInfo.experienced.push(data);
    },
    deleteExperiencedMoudle: function deleteExperiencedMoudle(state, data) {
      if (data.type === 'Experienced') {
        var i = state.resumeInfo.experienced.findIndex(function (d) {
          return d.name === data.name;
        });

        if (i > -1) {
          changeIndex(state.resumeInfo.experienced[i].index);
          state.resumeInfo.experienced.splice(i, 1);
        }
      } else {
        state.resumeInfo[data.type] = [];
        changeIndex(state.resumeInfo[data.type + 'Index']);
      }

      function changeIndex(index) {
        var _iterator6 = _createForOfIteratorHelper(state.resumeInfo.experienced),
            _step6;

        try {
          for (_iterator6.s(); !(_step6 = _iterator6.n()).done;) {
            var _item = _step6.value;

            if (_item.index > index) {
              _item.index = _item.index - 1;
            }
          }
        } catch (err) {
          _iterator6.e(err);
        } finally {
          _iterator6.f();
        }

        var keys = ['languageSkillsIndex', 'individualWorksIndex', 'expertiseIndex'];

        for (var _i = 0, _keys = keys; _i < _keys.length; _i++) {
          var item = _keys[_i];

          if (state.resumeInfo[item] > index) {
            state.resumeInfo[item] = state.resumeInfo[item] - 1;
          }
        }
      }
    },
    setSuggestion: function setSuggestion(state, data) {
      state.Suggestion = data.suggestion;
      state.ResumeScore = data.mark;
    },
    setFileState: function setFileState(state, data) {
      state.filePath = data;
      state.fileName = state.filePath.file.name.split('.').slice(0, -1).join('.');
    },
    setFileId: function setFileId(state, data) {
      state.fileId = data;
    },
    setFooterStatus: function setFooterStatus(state, data) {
      state.showFooter = data;
    },
    setEditStatus: function setEditStatus(state, data) {
      state.isEdit = data;
    },
    setbackIndex: function setbackIndex(state, data) {
      state.backIndex = data;
    },
    setDownLoad: function setDownLoad(state, data) {
      state.toDownLoad = data;
    },
    saveConversationId: function saveConversationId(state, data) {
      state.conversation_id = data;
    },
    saveSkills: function saveSkills(state, data) {
      state.isEdit = true;
      var i = state.resumeInfo[data.key].findIndex(function (d) {
        return d.key1 === data.data.key1;
      });

      if (i > -1) {
        state.resumeInfo[data.key][i] = _objectSpread({}, data.data);
      } else {
        if (state.resumeInfo[data.key].length === 0) {
          var count = state.resumeInfo.experienced.length + 2;

          if (state.resumeInfo.individualWorks.length > 0) {
            count = count + 1;
          }

          if (state.resumeInfo.expertise.length > 0) {
            count = count + 1;
          }

          if (state.resumeInfo.languageSkills.length > 0) {
            count = count + 1;
          }

          state.resumeInfo[data.key + 'Index'] = count + 1;
        }

        state.resumeInfo[data.key].push(data.data);
      }
    }
  },
  actions: {},
  modules: {}
});