import "core-js/modules/es.array.for-each";
import "core-js/modules/es.function.name";
import "core-js/modules/web.dom-collections.for-each";
import "vant/es/overlay/style";
import _Overlay from "vant/es/overlay";
import "vant/es/lazyload/style";
import _Lazyload from "vant/es/lazyload";
import "vant/es/image-preview/style";
import _ImagePreview from "vant/es/image-preview";
import "vant/es/grid-item/style";
import _GridItem from "vant/es/grid-item";
import "vant/es/grid/style";
import _Grid from "vant/es/grid";
import "vant/es/checkbox-group/style";
import _CheckboxGroup from "vant/es/checkbox-group";
import "vant/es/checkbox/style";
import _Checkbox from "vant/es/checkbox";
import "vant/es/circle/style";
import _Circle from "vant/es/circle";
import "vant/es/notify/style";
import _Notify from "vant/es/notify";
import "vant/es/toast/style";
import _Toast from "vant/es/toast";
import "vant/es/swipe-item/style";
import _SwipeItem from "vant/es/swipe-item";
import "vant/es/swipe/style";
import _Swipe from "vant/es/swipe";
import "vant/es/notice-bar/style";
import _NoticeBar from "vant/es/notice-bar";
import "vant/es/tag/style";
import _Tag from "vant/es/tag";
import "vant/es/icon/style";
import _Icon from "vant/es/icon";
import "vant/es/popup/style";
import _Popup from "vant/es/popup";
import "vant/es/loading/style";
import _Loading from "vant/es/loading";
import "vant/es/cell-group/style";
import _CellGroup from "vant/es/cell-group";
import "vant/es/field/style";
import _Field from "vant/es/field";
import "vant/es/image/style";
import _Image from "vant/es/image";
import "vant/es/form/style";
import _Form from "vant/es/form";
import "vant/es/picker/style";
import _Picker from "vant/es/picker";
import "vant/es/tabbar-item/style";
import _TabbarItem from "vant/es/tabbar-item";
import "vant/es/steps/style";
import _Steps from "vant/es/steps";
import "vant/es/step/style";
import _Step from "vant/es/step";
import "vant/es/area/style";
import _Area from "vant/es/area";
import "vant/es/datetime-picker/style";
import _DatetimePicker from "vant/es/datetime-picker";
import "vant/es/dialog/style";
import _Dialog from "vant/es/dialog";
import "vant/es/switch/style";
import _Switch from "vant/es/switch";
import "vant/es/tabbar/style";
import _Tabbar from "vant/es/tabbar";
import "vant/es/uploader/style";
import _Uploader from "vant/es/uploader";
import "vant/es/button/style";
import _Button from "vant/es/button";
var pluginsVant = [_Button, _Uploader, _Tabbar, _Switch, _Dialog, _DatetimePicker, _Area, _Step, _Steps, _TabbarItem, _Picker, _Form, _Image, _Field, _CellGroup, _Loading, _Popup, _Icon, _Tag, _NoticeBar, _Swipe, _SwipeItem, _Toast, _Notify, _Circle, _Checkbox, _CheckboxGroup, _Grid, _GridItem, _ImagePreview, _Lazyload, _Overlay];
export var vantPlugins = {
  install: function install(vm) {
    pluginsVant.forEach(function (item) {
      vm.component(item.name, item);
    });
  }
};