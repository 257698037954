import { ref, reactive, onMounted } from 'vue';
import { useStore } from 'vuex';
import axios from 'axios';
import { useRouter } from 'vue-router';
import TopBar from '../../../components/TopBar';
import { getSingleResume, optimizationResume } from 'api/MyResume';
import store from '../../../store';
export default {
  name: 'OptimizeIndex',
  components: {
    TopBar: TopBar
  },
  setup: function setup() {
    var $store = useStore();
    var router = useRouter();
    var scrollText = $store.state.scrollText;
    var state = reactive({
      file_name: '',
      file_id: '',
      template_name: 123,
      status: '',
      ResumeScore: 0,
      Suggestion: [],
      top_title: '简历优化',
      btns: [{
        text: '',
        functionName: ''
      }]
    });

    var getFileInfo = function getFileInfo() {
      // state.file_name = store.state.fileName
      state.file_id = store.state.fileId; // store.commit('getResumeInfo', obj)

      getSingleResume(state.file_id).then(function (resp) {});
    };

    var toTemplate = function toTemplate() {
      router.go(-1); // router.push({
      //   path: 'editresume',
      //   query: {
      //     id: state.file_id,
      //     name: state.file_name,
      //     status: state.status,
      //     template_name: state.template_name
      //   }
      // })
    };

    var BackIndex = function BackIndex() {
      $store.commit('setbackIndex', true);
      router.go(-1); // router.push('/resume_optimize')
    };

    var getAdvice = function getAdvice() {
      state.ResumeScore = $store.state.ResumeScore;
      state.Suggestion = $store.state.Suggestion;
    };

    onMounted(function () {
      state.notice_text = $store.state.scrollText;
      state.file_id = $store.state.fileId;
      getAdvice();
      getFileInfo();
    });
    return {
      state: state,
      scrollText: scrollText,
      toTemplate: toTemplate,
      BackIndex: BackIndex,
      getAdvice: getAdvice
    };
  }
};