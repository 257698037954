import "core-js/modules/es.number.constructor";
export default {
  name: 'Progress',
  props: {
    width: {
      // 进度条总宽度
      type: Number,
      default: 600
    },
    progress: {
      // 当前进度
      type: Number,
      default: 36
    },
    strokeWidth: {
      // 进度条线的宽度
      type: Number,
      default: 8
    },
    showInfo: {
      // 是否显示进度数值或状态图标
      type: Boolean,
      default: true
    }
  }
};