import request from './../util/request.js'

export function getAllResume (id) {
  return request({
    url: `/user/resumes/${id}`,
    method: 'get'
  })
}

export function getSingleResume (id) {
  return request({
    url: `/resume/info/${id}`,
    method: 'get'
  })
}

export function deleteResume (id) {
  return request({
    url: `/resume/info/${id}`,
    method: 'delete'
  })
}

export function saveResume (data) {
  return request({
    url: `/resume/info`,
    data: data,
    method: 'put'
  })
}

export function localOptimizationResume (data) {
  return request({
    url: `/resume/better`,
    data: data,
    method: 'post'
  })
}

export function optimizationResume (id) {
  return request({
    url: `/resume/optimize/${id}`,
    method: 'get'
  })
}

export function uploadResume (formData) {
  return request({
    url: `/resume/upload`,
    data: formData,
    method: 'post'
  })
}

export function diagnosisResume (id) {
  return request({
    url: `/resume/diagnosis/${id}`,
    method: 'get'
  })
}

export function wechatPay (data) {
  return request({
    url: `/resume/wechatpay`,
    method: 'post',
    data: data
  })
}

export function wechatLogin (data) {
  return request({
    url: `/user/loginbywechat`,
    data: {
      'code':data
    },
    method: 'post'
  })
}

export function InterViewChat (id,ask_str,user_id) {
  if (ask_str === ''){
    ask_str = '你是谁'
  }
  return request({
    url: `/resume/interviewai`,
    data:{ conversation_id: id, ask_str: ask_str, user_id: user_id },
    method: 'post'
  })
}

export function getMyInfo (id) {
  return request({
    url: `/user/info/${id}`,
    method: 'get'
  })
}

export function getUserOrder (id) {
  return request({
    url: `/user/payrecord/${id}`,
    method: 'get'
  })
}

export function getUserRecord (id) {
  return request({
    url: `/user/userpoint/${id}`,
    method: 'get'
  })
}
