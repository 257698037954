import "core-js/modules/es.number.to-fixed";
import "core-js/modules/es.regexp.exec";
import "core-js/modules/es.string.replace";
import { getTemplateJSON, replaceData } from '../../util/template';
import { getSingleResume } from '../../api/MyResume';
import { htmltofile } from '../../api/utils';
import TopBar from '../../components/TopBar';
export default {
  name: 'ResumePreview',
  components: {
    TopBar: TopBar
  },
  data: function data() {
    return {
      html: '',
      scale: 1,
      height: 0,
      height_: 0,
      top_title: '简历预览',
      btns: [{
        text: '',
        functionName: ''
      }]
    };
  },
  mounted: function mounted() {
    this.getResumeData();
  },
  methods: {
    goto: function goto(path) {
      this.$router.push({
        path: path,
        query: {
          id: this.$route.query.id,
          status: this.$route.query.status,
          template_name: this.$route.query.template_name
        }
      });
    },
    calculateTheRatioColumn: function calculateTheRatioColumn(height) {
      var width = window.innerWidth;
      var targetWidth = width - 30;
      this.scale = (targetWidth / 1033).toFixed(2);
      this.height = this.scale * height;
      this.height_ = height;
    },
    getResumeData: function getResumeData() {
      var _this = this;

      var data = getTemplateJSON(this.$route.query.template_name);
      this.calculateTheRatioColumn(data.height);
      getSingleResume(this.$route.query.id).then(function (res) {
        var resData = JSON.parse(res.data.resume_data);
        _this.html = replaceData(data, resData);
      });
    },
    download: function download() {
      var html = getTemplateJSON('template1');
      var newStr = html.html.replace('HTMLContent', this.html);
      var data = {
        html_str: newStr
      };
      htmltofile(data).then(function (res) {});
    }
  }
};