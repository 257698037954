import "core-js/modules/es.function.name";
import "core-js/modules/es.number.constructor";
import "core-js/modules/es.regexp.exec";
import "core-js/modules/es.string.replace";
import _createForOfIteratorHelper from "D:/\u7B80\u5386GPT/resumechatgpt/node_modules/@babel/runtime/helpers/esm/createForOfIteratorHelper";
import template1 from '../template/template1.json';
import template2 from '../template/template2.json';
import template3 from '../template/template3.json';
import template4 from '../template/template4.json';
var templates = {
  template1: template1,
  template2: template2,
  template3: template3,
  template4: template4
};
export function getTemplateJSON(name) {
  return templates[name];
}

function formatData(data) {
  var obj = {
    //基本信息
    baseInfo: {},
    experienced: [],
    languageSkills: [],
    languageSkillsIndex: '',
    individualWorks: [],
    individualWorksIndex: '',
    expertise: [],
    jobIntent: [],
    expertiseIndex: ''
  };

  var _iterator = _createForOfIteratorHelper(data),
      _step;

  try {
    for (_iterator.s(); !(_step = _iterator.n()).done;) {
      var item = _step.value;
      item.index = Number(item.index);

      if (item.name === '基本信息') {
        obj.baseInfo = item.content;
      } else if (item.name === '个人简介') {
        var str = '';

        var _iterator2 = _createForOfIteratorHelper(item.content),
            _step2;

        try {
          for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
            var s = _step2.value;
            str = str + s;
          }
        } catch (err) {
          _iterator2.e(err);
        } finally {
          _iterator2.f();
        }

        obj.introduction = str;
      } else if (item.name === '求职意向') {
        obj.jobIntent = item.content;
      } else if (item.name === '语言能力') {
        obj.languageSkills = item.content;
        obj.languageSkillsIndex = item.index;
      } else if (item.name === '专业技能') {
        obj.expertise = item.content;
        obj.expertiseIndex = item.index;
      } else if (item.name === '个人作品') {
        obj.individualWorks = item.content;
        obj.individualWorksIndex = item.index;
      } else {
        obj.experienced.push(item);
      }
    }
  } catch (err) {
    _iterator.e(err);
  } finally {
    _iterator.f();
  }

  return obj;
}

export function replaceData(html, data_) {
  var data = formatData(data_);
  var allHtml = '';
  data.experienced.reverse(); //基本信息

  var baseHtml = html.baseInfo.HTML;

  for (var key in html.baseInfo.rules) {
    var val = html.baseInfo.rules;
    baseHtml = baseHtml.replace(val[key], data.baseInfo[key]);
  } //个人简介


  var introductionHtml = html.introduction.HTML;

  for (var _key in html.introduction.rules) {
    var _val = html.introduction.rules;
    introductionHtml = introductionHtml.replace(_val[_key], data[_key]);
  } //各种经历


  var _iterator3 = _createForOfIteratorHelper(data.experienced),
      _step3;

  try {
    for (_iterator3.s(); !(_step3 = _iterator3.n()).done;) {
      var item = _step3.value;
      var outHtml = html.module.outHtml;
      outHtml = outHtml.replace('name', item.name);
      var modlue = '';

      var _iterator4 = _createForOfIteratorHelper(item.content),
          _step4;

      try {
        for (_iterator4.s(); !(_step4 = _iterator4.n()).done;) {
          var item_ = _step4.value;
          var content = html.module.content;
          var key1 = html.module.key1;
          var key2 = html.module.key2;
          var key3 = html.module.key3;
          var key4 = html.module.key4;
          var key6 = html.module.key6;

          if (item_.key1) {
            key1 = key1.replace('key1', item_.key1);
            content = content.replace('key1', key1);
          } else {
            content = content.replace('key1', '');
          }

          if (item_.key2) {
            key2 = key2.replace('key2', item_.key2);
            content = content.replace('key2', key2);
          } else {
            content = content.replace('key2', '');
          }

          if (item_.key3) {
            key3 = key3.replace('key3', item_.key3);
            content = content.replace('key3', key3);
          } else {
            content = content.replace('key3', '');
          }

          if (item_.key4) {
            key4 = key4.replace('key4', item_.key4);
            content = content.replace('key4', key4);
          } else {
            content = content.replace('key4', '');
          }

          if (item_.key6) {
            key6 = key6.replace('key6', item_.key6);
            content = content.replace('key6', key6);
          } else {
            content = content.replace('key6', '');
          }

          modlue = modlue + content;
        }
      } catch (err) {
        _iterator4.e(err);
      } finally {
        _iterator4.f();
      }

      outHtml = outHtml.replace('content', modlue);
      allHtml = outHtml + allHtml;
    }
  } catch (err) {
    _iterator3.e(err);
  } finally {
    _iterator3.f();
  }

  allHtml = baseHtml + introductionHtml + allHtml;
  return html.containerHtml.replace('innerHtml', allHtml);
}