<template>
  <div class="app-container">
    <div class='page_header'>
      <div class='page_header_title' v-if="state.active === 0">简历优化</div>
      <div class='page_header_title' v-if="state.active !== 0 && state.active < 3">
        <van-icon @click="BackStep" name="arrow-left"/>
      </div>
      <div class='page_header_title' v-if="state.active === 3">
        <van-icon @click="BackResume" name="arrow-left"/>
      </div>
      <div class='page_header_title_center' v-if="state.active !== 0">简历优化</div>
      <div class='page_header_btn'></div>
    </div>
    <div class='page_header_'></div>
    <div class='notice'>
      <div class='notice_bar'>
        <van-notice-bar
          left-icon='volume-o'
          :text='scrollText'
        />
      </div>
      <div class='notice_btn'>
<!--        <van-icon name='arrow'/>-->
      </div>
    </div>
    <div class='notice_'></div>
    <div class="my_form">
<!--      <div class="steps"></div>-->
      <div class="total">
        <!--        <transition-group-->
        <!--          :enter-active-class="state.isBack === true ? 'showCamera_back':'showCamera'"-->
        <!--          :leave-active-class="state.isBack === true ? 'hideCamera_back':'hideCamera'"-->
        <!--        >-->
        <div class="content" v-if="state.active === 0">
          <div class="logo">
            <img src="../../../assets/images/robot.png" alt="">
          </div>
          <div class="input">
            <div class="input_name_word">请输入姓名</div>
            <div class="input_fillin">
              <input v-model="state.userName" style="text-align: center" type="text">
            </div>
          </div>
          <div class="next_step">
            <p @click="NextStep">下一步</p>
          </div>
        </div>
        <!--        </transition-group>-->
        <!--        <transition-group-->
        <!--          appear-->
        <!--          :enter-active-class="state.isBack === true ? 'showCamera_back':'showCamera'"-->
        <!--          :leave-active-class="state.isBack === true ? 'hideCamera_back':'hideCamera'"-->
        <!--        >-->
        <div class="content" v-if="state.active === 1">
          <div class="logo">
            <img src="../../../assets/images/robot.png" alt="">
          </div>
          <div class="input">
            <div class="input_name_word">请输入求职岗位</div>
            <div class="input_fillin">
              <input v-model="state.job" style="text-align: center" type="text">
            </div>
          </div>
          <div class="next_step">
            <p @click="NextStep2">下一步</p>
            <div @click="NextStep2" style="position: absolute;color: rgba(79, 79, 79, 1);right: -50px;top: 0">跳过</div>
          </div>
          <div class="hot_job">
            <img src="../../../assets/images/hotjob.png" alt="">
            <div class="job_word">产品经理&nbsp;&nbsp;&nbsp;&nbsp;研发工程师&nbsp;&nbsp;&nbsp;&nbsp;前端工程师&nbsp;&nbsp;&nbsp;&nbsp;大数据工程师</div>
          </div>
        </div>
        <!--        </transition-group>-->
        <!--        <transition-group-->
        <!--          appear-->
        <!--          :enter-active-class="state.isBack === true ? 'showCamera_back':'showCamera'"-->
        <!--          :leave-active-class="state.isBack === true ? 'hideCamera_back':'hideCamera'"-->
        <!--        >-->
        <div class="content" v-if="state.active === 2">
          <div class="logo">
            <img  v-if="state.steps === '1'" src="../../../assets/images/robot.png" alt="">
            <MyLoading v-if="state.steps === '2' || state.steps === '2_'" :flag='false' style="top: -70px;scale: 0.8"></MyLoading>
            <MyLoading v-if="state.steps === '3'" :flag='false' style="top: -70px;scale: 0.8"></MyLoading>
            <MyLoading v-if="state.steps === '3_'" :flag='true' style="top: -70px;scale: 0.8"></MyLoading>
          </div>
          <div class="input">
            <div class="input_name_word" v-if="state.steps === '1'">请上传(PDF、WORD）</div>
            <div class="input_name_word" v-if="state.steps === '2' || state.steps === '2_'">简历上传中</div>
            <div class="input_name_word" v-if="state.steps === '3'">简历诊断中</div>
            <div class="input_fillin" id="file_choose">
              <van-uploader class="upload_file"
                            :after-read="onRead"
                            :max-count="1" accept="file">
                <input
                  :style="state.file_name === '' ? 'text-align: center;font-size: 24px' : 'text-align: center;font-size: 16px'"
                  :value="state.file_name === '' ? '+' : state.file_name "
                  type="text">
              </van-uploader>
            </div>
          </div>

          <van-loading class="loading-bg" type="spinner" color="#303030" size="24px" v-if="state.loading"/>
          <div class="next_step" v-if="state.next_loading === false">
            <p v-if="state.upload === ''" @click="Diagnosis()">开始诊断</p>
            <p v-if="state.upload === 'al_upload'" @click="DiagnosisFile()">开始诊断</p>
          </div>
          <div class="next_step_grey" v-if="state.next_loading === true">
            <p>开始诊断</p>
          </div>
        </div>
        <!--        </transition-group>-->
        <div class="content" v-if="state.active === 3">
          <div class="logo">
            <img src="../../../assets/images/robot.png" alt="">
          </div>
          <div class="input_name_word">恭喜您，您的简历已经优化完成了</div>
          <div class="next_step">
            <p @click="downLoad()">下载简历</p>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import { ref, reactive, onMounted } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { Notify,Uploader } from 'vant'
import { diagnosisResume, uploadResume, wechatLogin } from 'api/MyResume'
import store from '@/store'
import MyLoading from 'components/Loading.vue'

export default {
  name: 'OptimizeIndex',
  components: {
    MyLoading: MyLoading,
  },
  setup: function () {
    const $store = useStore()
    const router = useRouter()
    const scrollText = $store.state.scrollText
    const state = reactive({
      active: 0,
      file_name: '',
      loading: false,
      score: '',
      isBack: false,
      isPlay: true,
      userName: '',
      job: '',
      file_id: '',
      formData: '',
      steps: '1',
      upload: '',
      next_loading: false,

      code:''
    })
    const NextStep = () => {
      if (state.userName === '') {
        Notify('姓名为空，请填写')
      } else {
        state.isBack = false
        state.active++
      }
    }

    const NextStep2 = () => {
      if (state.job === '') {
        Notify('求职岗位为空，请填写')
      } else {
        state.isBack = false
        state.active++
      }
    }

    const BackStep = () => {
      state.isBack = true
      state.active--
    }

    const onRead = (file) => {
      $store.commit('setFileState', file)
      state.file_name = $store.state.fileName
      const formData = new FormData()
      formData.append('file', $store.state.filePath.file)
      formData.append('user_id', $store.state.userInfo.id)
      formData.append('resume_username', state.userName)
      formData.append('resume_workname', state.job)

      state.formData = formData
    }
    const Diagnosis = () => {
      state.next_loading = true
      if (state.file_name === '') {
        Notify('请上传简历')
      } else {
        //简历上传中
        state.steps = '2'
        state.loading = true
        uploadResume(state.formData).then(resp => {
          //简历上传完成
          state.steps = '2_'
          setTimeout(() => {
            state.steps = '3'
            state.file_id = resp.data
            $store.commit('setFileId', resp.data)
            DiagnosisFile()
          }, 3000)
        })
      }
    }

    const DiagnosisFile = () => {
      state.steps = '3'
      state.next_loading = true
      diagnosisResume(state.file_id).then(resp => {
        const obj_ = JSON.parse(resp.data)
        $store.commit('setSuggestion', obj_)
        state.steps = '3_'
        toOptimize()
      }).catch((err) => {
        if (err.status === 'fail') {
          Notify('服务器繁忙，请重新操作')
          state.loading = false
          state.next_loading = false
        }
      })
    }

    const toOptimize = () => {
      router.push('/resume_score')
    }

    const BackResume = () => {
      router.go(-1)
    }

    const downLoad = () => {
      Notify('暂时无法下载')
    }

    const getActive = () => {
      if (!router.currentRoute.value.query.file_id) {
        console.log('没跳转')
      } else {
        state.upload = router.currentRoute.value.query.file_status
        state.file_name = router.currentRoute.value.query.file_name
        state.file_id = router.currentRoute.value.query.file_id
        state.active = 2
      }
      state.isBack = $store.state.backIndex
      if ($store.state.fileName !== null && !router.currentRoute.value.query.file_name) {
        state.file_name = $store.state.fileName
      }
      if ($store.state.backIndex === true) {
        state.active = 2
      }
      if ($store.state.toDownLoad === true) {
        state.active = 3
      }
    }
    const getUrlCode = (name) => {
      return (
        decodeURIComponent(
          (new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(
            location.href
          ) || [, ''])[1].replace(/\+/g, '%20')
        ) || null
      )
    }

    const wxLogin = () => {
      wechatLogin(getUrlCode('code')).then(resp => {
        if (resp.status === 'success'){
          localStorage.setItem('access_token', resp.data.access_token);
          localStorage.setItem('refresh_token', resp.data.refresh_token);
          localStorage.setItem('id', resp.data.id);
        }
        $store.commit('getUserInfo', resp.data)
      })
    }
    onMounted(() => {
      getActive()
      state.notice_text = $store.state.scrollText
      wxLogin()
    })

    return {
      getActive,
      state,
      scrollText,
      BackStep,
      NextStep,
      NextStep2,
      Diagnosis,
      onRead,
      BackResume,
      downLoad,
      DiagnosisFile
    }
  }

}
</script>

<style scoped>
.page_header_title_center {
  font-size: 20px;
  color: #2A2E31;
  font-weight: bold;
}

.job_word {
  margin-top: 10px;
  color: rgba(79, 79, 79, 1);
}

.hot_job img {
  width: 70px;
  height: 20px;
}

.hot_job {
  position: absolute;
  bottom: -45px;
  left: 55px;
}

.showCamera {
  animation: fadeInRight;
  animation-duration: 1s;
}

.hideCamera {
  animation: fadeOutLeft;
  animation-duration: 1s;
}

.showCamera_back {
  animation: fadeInLeft;
  animation-duration: 1s;
}

.hideCamera_back {
  animation: fadeOutRight;
  animation-duration: 1s;
}

.loading-bg {
  height: 37px;
  width: 24px;
  margin: 10% auto 0;
}

.total {
  height: 500px;
}

.content {
  position: absolute;
  width: 100%;
}

.my_form {
  margin-bottom: 60px;
  position: relative;
}

.page_header {
  display: flex;
  justify-content: space-between;
  padding: 0 15px;
  align-items: center;
  height: 50px;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  background-color: #fff;
  z-index: 999;
}

.page_header .van-icon-arrow-left {
  font-size: 20px;
  color: rgba(0, 0, 0, 0.6);
  font-weight: bolder;
}

.page_header_title {
  font-size: 20px;
  color: #2A2E31;
  font-weight: 700;
}

.page_header_btn_container {
  width: 30px;
  justify-content: flex-start;
  display: flex;
  height: 20px;
  position: relative;
}

.page_header_btn_container_ {
  display: flex;
  width: 110px;
  position: absolute;
  justify-content: flex-end;
  top: 0;
  line-height: 20px;
  right: 0;
}

.page_header_btn {
  font-size: 15px;
  color: #0164FA;
}

.page_header_btn_container_ span {
  margin: 0 3px;
  color: #0164FA;
}

.next_step_grey {
  position: relative;
  width: 151px;
  height: 37px;
  border-radius: 20px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 37px;
  text-align: center;
  letter-spacing: 0px;
  text-align: center;
  color: #fff;

  background-color: rgb(137, 151, 178);
  margin: 6% auto 0;
}

.next_step {
  position: relative;
  width: 151px;
  height: 37px;
  border-radius: 20px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 37px;
  text-align: center;
  letter-spacing: 0px;
  text-align: center;
  color: #fff;

  background-color: rgba(1, 100, 250, 1);
  margin: 6% auto 0;
}

.input {
  position: relative;
  text-align: center;
  width: 100%;
  margin: 10% auto 0;
}

.upload_file {
  width: 100%;
  text-align: center;
}

#file_choose >>> .van-uploader__wrapper {
  justify-content: center !important;
}

.input_fillin input {
  height: 40px;
  background: #F2F2F2;
  border-radius: 10px;
  width: 260px;
  outline: none;
  border: none;
  margin-top: 15px;

  font-size: 20px;
  color: rgba(130, 130, 130, 1);
}

.input_name_word {
  margin-top: 20px;
  height: 22px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 22px;

  text-align: center;

  color: #000000;
}

.logo {
  height: 150px;
  width: 95%;
  margin: 8% auto 0;
  /*background: url("../../../assets/images/robot.png");*/
  /*background-repeat: no-repeat;*/
  /*background-size: contain;*/
}

.logo img {
  position: absolute;
  left: 50%;
  margin-left: -113px;
  /*height: 150px;*/
  width: 226px;
}

.steps {
  width: 250px;
  height: 50px;
  margin: 5% auto 0;
  background-image: url("../../../assets/images/steps.png");
  background-repeat: no-repeat;
  background-size: contain;
}
</style>
