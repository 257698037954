import "core-js/modules/es.function.name";
import "core-js/modules/es.regexp.exec";
import "core-js/modules/es.string.split";
import _createForOfIteratorHelper from "D:/\u7B80\u5386GPT/resumechatgpt/node_modules/@babel/runtime/helpers/esm/createForOfIteratorHelper";
import _objectSpread from "D:/\u7B80\u5386GPT/resumechatgpt/node_modules/@babel/runtime/helpers/esm/objectSpread2";
import "vant/es/toast/style";
import _Toast from "vant/es/toast";
import "vant/es/dialog/style";
import _Dialog from "vant/es/dialog";
import store from '../../../store';
import TopBar from '../../../components/TopBar';
import { formatTime_YMD } from '../../../util/index';
import { localOptimizationResume as _localOptimizationResume } from '../../../api/MyResume';
export default {
  //项目经历
  name: 'ProjectExperience',
  components: {
    TopBar: TopBar
  },
  data: function data() {
    return {
      currentDate: new Date(),
      currentDate2: new Date(),
      checked: '',
      index: '',
      AiMessage: '',
      optimization_idea: '',
      showCalendar: false,
      showAiContent: false,
      showCalendar2: false,
      info: {
        key1: '',
        key2: '',
        key2_: '',
        key3: '',
        key4: '',
        key5: '',
        key6: ''
      },
      top_title: '项目经历',
      btns: [{
        text: '确认',
        functionName: 'saveInfo'
      }],
      flag: '' //true 编辑   false  新增

    };
  },
  mounted: function mounted() {
    this.index = this.$route.query.index;
    this.flag = !!this.index;

    if (this.flag) {
      this.getInfo();
    }
  },
  methods: {
    deleteExperience: function deleteExperience() {
      var _this = this;

      _Dialog.confirm({
        title: '提示：',
        message: '是否删除该条项目经历？'
      }).then(function () {
        var data = {
          type: '项目经历',
          index: _this.index
        };

        _this.$store.commit('deleteExperienced', data);

        _this.$router.go(-1);

        _Toast.success('删除成功！');
      }).catch(function () {// on cancel
      });
    },
    startOptimizing: function startOptimizing() {
      if (this.info.key6 !== '') {
        this.showAiContent = true;
        this.localOptimizationResume();
      } else {
        _Toast.fail('请填写内容再进行优化！');
      }
    },
    localOptimizationResume: function localOptimizationResume() {
      var that = this;

      var info = _objectSpread({}, this.info);

      info.key2 = info.key2 + '~' + info.key2_;
      delete info.key2_;
      that.AiMessage = '';
      var data = {
        'resume_id': this.$route.query.id,
        //简历id
        'conversation_id': store.state.conversation_id,
        //会话id,首次会话提交空的字符串，根据返回的 会话id，开始下一段会话。
        'order': '让内容看起来更专业更丰富',
        //用户提出的优化指令
        'ask_str': JSON.stringify(info) //需要优化的内容字符串

      };

      _localOptimizationResume(data).then(function (res) {
        var resdata = JSON.parse(res.data);
        that.optimization_idea = resdata.optimization_idea;
        that.info.key1 = resdata.optimization_result.key1;
        that.info.key3 = resdata.optimization_result.key3;
        that.info.key4 = resdata.optimization_result.key4;
        that.info.key5 = resdata.optimization_result.key5;
        that.AiMessage = resdata.optimization_result.key6;
        var arr = resdata.optimization_result.key2.split('~');
        that.info.key2 = arr[0];

        if (arr.length > 1) {
          that.info.key2_ = arr[1];
        }

        that.$store.commit('saveConversationId', res.conversation_id);
      }).catch(function (err) {
        that.localOptimizationResume();
      });
    },
    coverMessage: function coverMessage() {
      if (this.AiMessage) {
        this.info.key6 = this.AiMessage;
      }
    },
    insetMessage: function insetMessage() {
      if (this.AiMessage) {
        this.info.key6 = this.info.key6 + this.AiMessage;
      }
    },
    onConfirm: function onConfirm(date) {
      this.info.key2 = formatTime_YMD(date);
      this.showCalendar = false;
    },
    onConfirm2: function onConfirm2(date) {
      var day = new Date();
      var str = formatTime_YMD(day);
      var formatStr = formatTime_YMD(date);
      this.info.key2_ = formatTime_YMD(date);

      if (str === formatStr) {
        this.info.key2_ = '至今';
      } else {
        this.info.key2_ = formatStr;
      }

      this.showCalendar2 = false;
    },
    getInfo: function getInfo() {
      var _iterator = _createForOfIteratorHelper(store.state.resumeInfo.experienced),
          _step;

      try {
        for (_iterator.s(); !(_step = _iterator.n()).done;) {
          var item = _step.value;

          if (item.name === '项目经历') {
            var data = item.content[this.index];
            this.info = _objectSpread({}, data);
            var arr = data.key2.split('~');
            this.info.key2 = arr[0];

            if (arr.length > 1) {
              this.info.key2_ = arr[1];
            }
          }
        }
      } catch (err) {
        _iterator.e(err);
      } finally {
        _iterator.f();
      }
    },
    saveInfo: function saveInfo() {
      var info = _objectSpread({}, this.info);

      info.key2 = info.key2 + '~' + info.key2_;
      delete info.key2_;

      if (this.flag) {
        var data = {
          type: '项目经历',
          index: this.index,
          data: info
        };
        this.$store.commit('saveExperienced', data);
      } else {
        var _data = {
          type: '项目经历',
          data: info
        };
        this.$store.commit('addExperienced', _data);
      }

      this.$router.go(-1);
    }
  }
};