import "vant/es/toast/style";
import _Toast from "vant/es/toast";
import TopBar from '../../components/TopBar';
export default {
  name: 'Forgot',
  components: {
    TopBar: TopBar
  },
  data: function data() {
    return {
      top_title: '找回密码',
      btns: [{
        text: '',
        functionName: ''
      }],
      left: 0,
      left2: 500,
      flag: false,
      type: 'password',
      type2: 'password',
      phone: '',
      captcha: '',
      password: '',
      confirmPassword: '',
      isAllow: false,
      rightIcon2: require('../../assets/images/icon/eyes_off.png'),
      rightIcon: require('../../assets/images/icon/eyes_off.png')
    };
  },
  methods: {
    step1: function step1() {
      this.left = 0;
      this.left2 = 500;
      this.flag = false;
    },
    onSubmit: function onSubmit() {
      this.isAllow = true;
      this.step2();
    },
    onSubmit2: function onSubmit2() {},
    step2: function step2() {
      if (this.isAllow) {
        this.left = -500;
        this.left2 = 0;
        this.flag = true;
      } else {
        _Toast.fail('请先完成验证手机号码！');
      }
    },
    click_right_icon: function click_right_icon() {
      if (this.type === 'password') {
        this.type = 'text';
        this.rightIcon = require('../../assets/images/icon/eyes_on.png');
      } else {
        this.type = 'password';
        this.rightIcon = require('../../assets/images/icon/eyes_off.png');
      }
    },
    click_right_icon2: function click_right_icon2() {
      if (this.type2 === 'password') {
        this.type2 = 'text';
        this.rightIcon2 = require('../../assets/images/icon/eyes_on.png');
      } else {
        this.type2 = 'password';
        this.rightIcon2 = require('../../assets/images/icon/eyes_off.png');
      }
    }
  }
};