import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// 动画 animate.css
import animate from 'animate.css'
// 移动端适配
import 'lib-flexible/flexible.js'

// 引入全局样式
import '@/assets/scss/index.scss'
// 全局引入按需引入UI库 vant
import { vantPlugins } from './plugins/vant.js'
// 导入打字机
import vuetyped from 'vue3typed'

createApp(App).use(vuetyped).use(animate).use(store).use(router).use(vantPlugins).mount('#app')

//路由守卫
router.beforeEach((to, from, next) => {
  if (to.meta.requireAuth) { // 判断该路由是否需要登录权限
    // const token = '6yIYIralFGJvZnTEvWSrN0wlq_AX_xHBe36e5Itz_DfmnSIyfFjZ8fG6tCPaWiqGFpSt9IfrYLwiI'
    const token = localStorage.getItem('access_token')
    if (token) { // 通过sessionStorage获取当前的token是否存在
      next()
    } else {
      next({
        path: from.path,
        query: { redirect: to.fullPath } // 将跳转的路由path作为参数，登录成功后跳转到该路由
      })
    }
  } else {
    next()
  }
})
