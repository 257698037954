import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-015dbb1a"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = {
  class: "app-container"
};
var _hoisted_2 = {
  key: 0,
  class: "layout-footer"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_router_view = _resolveComponent("router-view");

  var _component_TabBar = _resolveComponent("TabBar");

  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", {
    class: "layout-content",
    style: _normalizeStyle({
      height: $setup.showFooter ? "calc(100% - 70px)" : "100%"
    })
  }, [_createVNode(_component_router_view)], 4), $setup.showFooter ? (_openBlock(), _createElementBlock("div", _hoisted_2, [_createVNode(_component_TabBar, {
    data: $setup.state.tabBar
  }, null, 8, ["data"])])) : _createCommentVNode("", true)]);
}