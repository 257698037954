import { ref, reactive, onMounted } from 'vue';
import { useStore } from 'vuex';
import { useRouter, useRoute } from 'vue-router';
import axios from 'axios';
import { getSingleResume } from '../../../api/MyResume';
import { htmltofile } from '../../../api/utils';
export default {
  name: 'OptimizeIndex',
  components: {},
  setup: function setup() {
    var $store = useStore();
    var router = useRouter();
    var route = useRoute();
    var scrollText = $store.state.scrollText;
    var state = reactive({
      active: 0,
      show: false,
      overlay: false,
      file_name: ''
    });

    var BackResume = function BackResume() {
      router.go(-1);
    };

    var showMask = function showMask() {
      state.show = true;
    };

    var downLoad = function downLoad(name) {
      state.overlay = true;
      state.show = false;
      getSingleResume(route.query.id).then(function (res) {
        var data = {
          html_str: res.data.template
        };
        htmltofile(data).then(function (res2) {
          var filename = res.data.resume_name + '.' + name;
          var url = 'http://files.haijigis.com:17371/static/' + res2.data;
          state.overlay = false;
          router.push('/download_finish'); // downloadFile(url,filename)

          downloadFile2(url, filename);
        });
      });
    };

    var downloadFile2 = function downloadFile2(url) {
      window.location.href = url;
    };

    var downloadFile = function downloadFile(url, filename) {
      var link = document.createElement('a');
      link.href = url;
      link.download = filename;
      link.target = '_blank';
      link.style.display = 'none';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    };

    onMounted(function () {
      state.notice_text = $store.state.scrollText;
    });
    return {
      state: state,
      scrollText: scrollText,
      showMask: showMask,
      BackResume: BackResume,
      downloadFile2: downloadFile2,
      downloadFile: downloadFile,
      downLoad: downLoad
    };
  }
};