import "core-js/modules/es.function.name";
import "vant/es/toast/style";
import _Toast from "vant/es/toast";
import _createForOfIteratorHelper from "D:/\u7B80\u5386GPT/resumechatgpt/node_modules/@babel/runtime/helpers/esm/createForOfIteratorHelper";
import _objectSpread from "D:/\u7B80\u5386GPT/resumechatgpt/node_modules/@babel/runtime/helpers/esm/objectSpread2";
import areaList from '../../../util/areaList';
import store from '../../../store';
import TopBar from '../../../components/TopBar';
export default {
  //基本信息
  name: 'BaseInfo',
  components: {
    TopBar: TopBar
  },
  data: function data() {
    return {
      top_title: '基本信息',
      btns: [{
        text: '确认',
        functionName: 'saveBaseInfo'
      }],
      identity: [{
        text: '学生',
        value: '学生'
      }, {
        text: '职场人士',
        value: '职场人士'
      }, {
        text: '工人',
        value: '工人'
      }, {
        text: '无业',
        value: '无业'
      }],
      gender: [{
        text: '男',
        value: '男'
      }, {
        text: '女',
        value: '女'
      }],
      showPicker: false,
      showPicker2: false,
      showPicker3: false,
      areaList: areaList,
      info: {
        "age": "",
        "city": "",
        "educational_background": "",
        "email": "",
        "sex": "",
        "mobile_phone": "",
        "name": "",
        "standing": ""
      }
    };
  },
  mounted: function mounted() {
    this.info = _objectSpread({}, store.state.resumeInfo.baseInfo);
  },
  methods: {
    onConfirm: function onConfirm(selectedOptions) {
      this.info.sex = selectedOptions.text;
      this.showPicker = false;
    },
    onConfirm2: function onConfirm2(selectedOptions) {
      this.info.identity = selectedOptions.text;
      this.showPicker2 = false;
    },
    onConfirm3: function onConfirm3(selectedOptions) {
      var text = '';

      var _iterator = _createForOfIteratorHelper(selectedOptions),
          _step;

      try {
        for (_iterator.s(); !(_step = _iterator.n()).done;) {
          var item = _step.value;

          if (text === '') {
            text = item.name;
          } else {
            text = text + '-' + item.name;
          }
        }
      } catch (err) {
        _iterator.e(err);
      } finally {
        _iterator.f();
      }

      this.info.city = text;
      this.showPicker3 = false;
    },
    goBack: function goBack() {
      this.$router.go(-1);
    },
    saveBaseInfo: function saveBaseInfo() {
      var obj = _objectSpread({}, this.info);

      _Toast.success('提交成功！');

      this.$store.commit('saveBaseInfo', obj);
      this.goBack();
    }
  }
};