<template>
  <div class="app-container" style="background-color: rgba(248, 248, 248, 1);">
    <div class="header_"></div>
    <div class="header">
      <div class="header_info">
        <div class="header_img" :style="{'background-image':'url(' + state.head_img + ')'}"></div>
        <div class="user_name">
          <div class="name_value">{{ state.nick_name }}</div>
          <img v-if="state.vip_is === 1" src="../../../assets/images/vipicon.png" alt="">
        </div>
        <div class="header_bell"></div>
      </div>
    </div>
    <div class="content">
      <div class="header_points">
        <div class="header_points_block">
          <div class="points_head">
            <div>点数</div>
            <div class="word1">{{ state.optimize_times }}</div>
          </div>
          <div class="recharge" @click="toRefund2('bean','/purchase')">
            立即充值
          </div>
          <div class="refund_background">
            <img src="../../../assets/images/refund.png" alt="">
          </div>

        </div>
        <div class="header_points_block">
          <div class="points_head">
            <div>会员套餐</div>
            <div class="word1">{{ state.vip_exp_time }}</div>
            <span style="color: #979797;font-size: 12px;margin-left: 5px;">天</span>
          </div>
          <div class="recharge" @click="toRefund2('vip','/purchase')">
            立即充值
          </div>
          <div class="refund_background">
            <img src="../../../assets/images/king_back.png" alt="">
          </div>

        </div>
      </div>
<!--      <div class="vip_combo"><img src="../../../assets/images/icon/king.png" alt="">-->
<!--        <div>我的会员套餐详情</div>-->
<!--      </div>-->
      <div class="big_block">
        <div class="row" @click="toRefund('/my_order')"><img src="../../../assets/images/icon/order.png" alt="">
          <div>我的订单</div>
        </div>
        <div class="row" @click="toRefund('/opinion')"><img src="../../../assets/images/icon/advice.png" alt="">
          <div>意见反馈</div>
        </div>
        <div class="row" @click="toRefund('/my_config')"><img src="../../../assets/images/icon/config.png" alt="">
          <div>我的设置</div>
        </div>
        <div class="row" @click="toRefund('/use_record')"><img src="../../../assets/images/icon/clock.png" alt="">
          <div>点数/会员套餐记录</div>
        </div>
      </div>
      <div class="big_block">
        <div class="row"><img src="../../../assets/images/icon/headear.png" alt="">
          <div>客服中心</div>
        </div>
        <div class="row"><img src="../../../assets/images/icon/wechat.png" alt="">
          <div>关注公众号</div>
        </div>
        <div class="row" @click="toRefund('/about_us')"><img src="../../../assets/images/icon/aboutus.png" alt="">
          <div>关于我们</div>
        </div>
        <div class="row"><img src="../../../assets/images/icon/version.png" alt="">
          <div>版本信息</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, reactive, onMounted } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import axios from 'axios'
import { Notify } from 'vant'
import store from '@/store'
import { getMyInfo } from 'api/MyResume'

export default {
  name: 'OptimizeIndex',
  components: {},
  setup () {
    const $store = useStore()
    const router = useRouter()
    const scrollText = $store.state.scrollText
    const state = reactive({
      userName: '',
      head_img: '',
      nick_name:'',
      optimize_times:'',
      vip_is:0,
      vip_exp_time:''
    })

    const toRefund = (value) => {
      router.push(value)
    }

    const toRefund2 = (type_value, value) => {
      router.push({
        path: value,
        query: {
          click_type: type_value
        }
      })
    }

    const getUserInfo = () =>{
      getMyInfo(localStorage.getItem('id')).then((value) =>{
        console.log(value)
        state.head_img = value.data.headimgurl
        state.nick_name = value.data.nickname
        state.optimize_times = value.data.optimize_times
        state.vip_is = value.data.vip_is
        state.vip_exp_time = value.data.vip_exp_time
      })
    }

    onMounted(() => {
      state.notice_text = $store.state.scrollText


      getUserInfo()
    })

    return {
      state,
      scrollText,
      toRefund,
      toRefund2,
    }
  }

}
</script>

<style scoped>
.refund_background img {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 80px;
}

.big_block {
  width: 100%;
  background-color: #fff;
  height: 180px;
  margin-bottom: 10px;
  border-radius: 10px;
  padding: 15px;
}

.row img {
  width: 21px;
  margin-right: 10px;
}

.row {
  margin-bottom: 20px;
  display: flex;
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0px;
}

.vip_combo img {
  width: 21px;
  margin-right: 10px;
}

.vip_combo {
  display: flex;
  padding: 15px;
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0px;

  width: 100%;
  background-color: #fff;
  height: 50px;
  margin-bottom: 10px;
  border-radius: 10px;
}

.recharge {
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: center;
  color: rgba(141, 77, 2, 1);

  margin-top: 10px;
  border-radius: 21px;
  width: 70px;
  height: 22px;
  background: linear-gradient(180deg, #FFE8BD 0%, #FDC0A5 100%);
}

.word1 {
  display: inline-block;
  margin-left: 10px;
  font-size: 24px;
  color: rgba(255, 126, 43, 1);
}

.points_head {
  display: flex;
}

.header_points_block {
  position: relative;
  padding: 15px;
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0px;

  width: 48%;
  height: 80px;
  border-radius: 10px;
  background-color: #fff;
}

.header_points {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

/*内容*/
.content {
  position: absolute;
  top: 85px;
  width: 100%;
  padding: 14px;
  padding-bottom: 100px;
}

/*头部*/
.header {
  position: absolute;
  width: 100%;
}

.header_bell {
  position: absolute;
  right: 10px;
  top: 5px;
  width: 30px;
  height: 30px;
  background-image: url("../../../assets/images/bell.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.user_name img {
  margin-left: 5px;
  flex-shrink: 0;
  width: 25px;
  height: 25px;
}

.user_name {
  align-items: center;
  display: flex;
  font-size: 15px;
  color: #fff;
  margin-left: 12px;
  height: 60px;
  line-height: 60px;
}

.header_ {
  position: fixed;
  width: 100%;
  height: 150px;
  background-image: url("../../../assets/images/header_bg.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position-y: bottom;
}

.header_info {
  display: flex;
  padding-left: 40px;
  padding-top: 20px;
}

.header_img {
  width: 60px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 50%;
}

.app-container {
  position: absolute;
  overflow: auto; /* 显示滚动条 */
}
</style>
