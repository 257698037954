import "core-js/modules/es.array.concat";
import { normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-f0fab84c"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = {
  class: "m-progress-outer"
};
var _hoisted_2 = {
  class: "m-progress-inner"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", {
    class: "m-progress",
    style: _normalizeStyle("width:".concat($props.width, "px;"))
  }, [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", {
    class: _normalizeClass(['u-progress-bg', {
      'u-success-bg': $props.progress >= 100
    }]),
    style: _normalizeStyle("width: ".concat($props.progress >= 100 ? 100 : $props.progress, "%; height: ").concat($props.strokeWidth, "px;"))
  }, null, 6)])]), $props.showInfo ? (_openBlock(), _createElementBlock(_Fragment, {
    key: 0
  }, [], 64)) : _createCommentVNode("", true)], 4);
}