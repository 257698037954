<template>
  <div class='page_header'>
    <div class='page_header_title' style='  font-size: 20px; color: #2A2E31; font-weight: 700;'>{{top_title}}</div>
    <div class='page_header_logo'></div>
  </div>
  <div class='page_header_'></div>
  <div class='notice'>
    <div class='notice_bar'>
      <van-notice-bar
        left-icon='volume-o'
        scrollable
        :text='scrollText'
      />
    </div>
    <div class='notice_btn'>
<!--      <van-icon name='arrow' />-->
    </div>
  </div>
  <div class='notice_'></div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'TopBar',
  props: {
    top_title: String
  },
  computed: {
    ...mapState(['scrollText'])
  },
  methods: {
    goBack () {
      this.$router.go(-1)
    }
  }
}
</script>

<style scoped>
.page_header_logo {
  width:45px;
  height: 45px;
  border-radius: 50%;
}

.page_header_btn:last-child span {
  display: none;
}
</style>
