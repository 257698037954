import "core-js/modules/es.array.concat";
import "core-js/modules/es.array.find-index";
import "core-js/modules/es.array.join";
import "core-js/modules/es.array.splice";
import "core-js/modules/es.function.name";
import _createForOfIteratorHelper from "D:/\u7B80\u5386GPT/resumechatgpt/node_modules/@babel/runtime/helpers/esm/createForOfIteratorHelper";
import "vant/es/toast/style";
import _Toast from "vant/es/toast";
import "vant/es/notify/style";
import _Notify from "vant/es/notify";
import store from '../../../store';
import TopBar from '../../../components/TopBar';
import { localOptimizationResume as _localOptimizationResume } from '../../../api/MyResume';
export default {
  //个人简介
  name: 'Biography',
  components: {
    TopBar: TopBar
  },
  data: function data() {
    return {
      message: '',
      top_title: '个人简介',
      showAiContent: false,
      btns: [{
        text: '确认',
        functionName: 'saveIntroduction'
      }],
      optimization_idea: '',
      height: 0,
      AiMessage: '',
      choseItem: [],
      directives: [{
        name: '内容',
        arr: ['专业', '严谨', '幽默']
      }, {
        name: '字数',
        arr: ['20-100字', '100-200字', '200-300字', '300-400字']
      }, {
        name: '其他',
        arr: ['润色内容', '按点罗列']
      }],
      timer: null
    };
  },
  mounted: function mounted() {
    if (store.state.resumeInfo !== null) {
      this.message = JSON.parse(JSON.stringify(store.state.resumeInfo.introduction));
    } else {
      _Notify('信息丢失，已退回上一级');

      this.$router.go(-1);
    }
  },
  methods: {
    changeDirective: function changeDirective() {
      this.height = 'auto';
    },
    clickChose: function clickChose(data) {
      var i = this.choseItem.findIndex(function (d) {
        return d.father === data.father;
      });

      if (i > -1) {
        this.choseItem.splice(i, 1);
      }
    },
    choseDirective: function choseDirective(father, data) {
      var obj = {
        father: father.name,
        data: data
      };
      var i = this.choseItem.findIndex(function (d) {
        return d.father === father.name;
      });

      if (i > -1) {
        if (this.choseItem[i].data !== data) {
          this.choseItem[i].data = data;
        } else {
          this.choseItem.splice(i, 1);
        }
      } else {
        this.choseItem.push(obj);
      }
    },
    isChose: function isChose(data) {
      var i = this.choseItem.findIndex(function (d) {
        return d.data === data;
      });
      return i > -1;
    },
    startOptimizing: function startOptimizing() {
      if (this.choseItem.length === 0) {
        _Toast.fail('请选择指令内容再进行优化！');

        return;
      }

      if (this.message !== '') {
        this.showAiContent = true;
        this.localOptimizationResume();
      } else {
        _Toast.fail('请填写内容再进行优化！');
      }
    },
    coverMessage: function coverMessage() {
      if (this.AiMessage) {
        this.message = this.AiMessage;
      }
    },
    insetMessage: function insetMessage() {
      if (this.AiMessage) {
        this.message = this.message + this.AiMessage;
      }
    },
    goBack: function goBack() {
      this.$router.go(-1);
    },
    saveIntroduction: function saveIntroduction() {
      this.$store.commit('saveIntroduction', this.message);
      this.goBack();
    },
    localOptimizationResume: function localOptimizationResume() {
      var _this = this;

      this.height = 0;
      var order = '';

      var _iterator = _createForOfIteratorHelper(this.choseItem),
          _step;

      try {
        for (_iterator.s(); !(_step = _iterator.n()).done;) {
          var item = _step.value;
          var ord = "\u5BF9".concat(item.father, "\u7684\u8981\u6C42\u662F").concat(item.data, ",");
          order = order + ord;
        }
      } catch (err) {
        _iterator.e(err);
      } finally {
        _iterator.f();
      }

      var that = this;
      that.AiMessage = '';
      var obj = {
        name: '个人简介',
        content: [store.state.resumeInfo.introduction]
      };
      var data = {
        'resume_id': this.$route.query.id,
        //简历id
        'conversation_id': store.state.conversation_id,
        //会话id,首次会话提交空的字符串，根据返回的 会话id，开始下一段会话。
        'order': order,
        //用户提出的优化指令
        'ask_str': JSON.stringify(obj) //需要优化的内容字符串

      };

      _localOptimizationResume(data).then(function (res) {
        var resdata = JSON.parse(res.data);
        that.optimization_idea = resdata.optimization_idea;
        var str = '';
        var newArray = [];
        newArray = resdata.optimization_result.content.join('\n');

        var _iterator2 = _createForOfIteratorHelper(newArray),
            _step2;

        try {
          for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
            var item = _step2.value;
            str = str + item;
          }
        } catch (err) {
          _iterator2.e(err);
        } finally {
          _iterator2.f();
        }

        that.AiMessage = str;
        that.$store.commit('saveConversationId', res.conversation_id);
      }).catch(function (err) {
        if (err.status === 'fail') {
          _this.localOptimizationResume();
        }
      });
    }
  }
};