import "core-js/modules/es.array.concat";
import "core-js/modules/es.array.find-index";
import "core-js/modules/es.array.for-each";
import "core-js/modules/es.function.name";
import "core-js/modules/es.object.to-string";
import "core-js/modules/es.regexp.exec";
import "core-js/modules/es.string.match";
import "core-js/modules/es.string.split";
import "core-js/modules/es.typed-array.uint8-array";
import "core-js/modules/es.typed-array.copy-within";
import "core-js/modules/es.typed-array.every";
import "core-js/modules/es.typed-array.fill";
import "core-js/modules/es.typed-array.filter";
import "core-js/modules/es.typed-array.find";
import "core-js/modules/es.typed-array.find-index";
import "core-js/modules/es.typed-array.for-each";
import "core-js/modules/es.typed-array.includes";
import "core-js/modules/es.typed-array.index-of";
import "core-js/modules/es.typed-array.iterator";
import "core-js/modules/es.typed-array.join";
import "core-js/modules/es.typed-array.last-index-of";
import "core-js/modules/es.typed-array.map";
import "core-js/modules/es.typed-array.reduce";
import "core-js/modules/es.typed-array.reduce-right";
import "core-js/modules/es.typed-array.reverse";
import "core-js/modules/es.typed-array.set";
import "core-js/modules/es.typed-array.slice";
import "core-js/modules/es.typed-array.some";
import "core-js/modules/es.typed-array.sort";
import "core-js/modules/es.typed-array.subarray";
import "core-js/modules/es.typed-array.to-locale-string";
import "core-js/modules/es.typed-array.to-string";
import "core-js/modules/web.dom-collections.for-each";
import _toConsumableArray from "D:/\u7B80\u5386GPT/resumechatgpt/node_modules/@babel/runtime/helpers/esm/toConsumableArray";
import "vant/es/dialog/style";
import _Dialog from "vant/es/dialog";
import _createForOfIteratorHelper from "D:/\u7B80\u5386GPT/resumechatgpt/node_modules/@babel/runtime/helpers/esm/createForOfIteratorHelper";
import "vant/es/toast/style";
import _Toast from "vant/es/toast";
import "vant/es/notify/style";
import _Notify from "vant/es/notify";
import _objectSpread from "D:/\u7B80\u5386GPT/resumechatgpt/node_modules/@babel/runtime/helpers/esm/objectSpread2";
import store from '../../../store';
import { mapState } from 'vuex';
import TopBar from '../../../components/TopBar';
import { getSingleResume, saveResume } from '../../../api/MyResume';
import { uploadFile } from '../../../api/utils';
import Cropper from "vue3-cropper";
import "vue3-cropper/lib/vue3-cropper.css";
export default {
  name: 'index',
  components: {
    TopBar: TopBar,
    Cropper: Cropper
  },
  data: function data() {
    return {
      show: false,
      elementOrder: [1, 2, 3, 4, 5, 6, 7],
      imagePath: 'https://fastly.jsdelivr.net/npm/@vant/assets/cat.jpeg',
      choseElement: '',
      showTip: false,
      CropperFlag: false,
      option: {},
      top_title: '简历编辑',
      btns: [{
        text: '保存',
        functionName: 'upDateResume'
      }],
      choseElementName: '',
      choseElementType: '',
      status: '',
      template_name: '',
      elementIndex: ''
    };
  },
  computed: _objectSpread({}, mapState(['resumeInfo', 'scrollText', 'userInfo'])),
  mounted: function mounted() {
    this.getinfo();

    if (this.$route.query.template_name) {
      this.ableDownload();
    }
  },
  methods: {
    ableDownload: function ableDownload() {
      var that = this;
      this.showTip = true;
      var time = setTimeout(function () {
        that.showTip = false;
        clearTimeout(time);
      }, 5000);
    },
    beforeRead: function beforeRead(file) {
      if (Array.isArray(file)) {
        file.forEach(function (item) {
          if (item.type !== 'image/jpeg') {
            return false;
          }
        });

        if (file.type !== 'image/jpeg') {
          return false;
        }
      }

      var reader = new FileReader();
      var that = this;
      reader.readAsDataURL(file); //读取图像文件 result 为 DataURL, DataURL 可直接 赋值给 img.src

      reader.onload = function (event) {
        that.CropperFlag = true;
        that.imagePath = event.target.result;
      };

      return true;
    },
    onOversize: function onOversize() {
      _Notify({
        type: 'warning',
        message: '文件不能超过10M！'
      });
    },
    base64toFile: function base64toFile(dataurl, filename) {
      var arr = dataurl.split(',');
      var mime = arr[0].match(/:(.*?);/)[1];
      var suffix = mime.split('/')[1];
      var bstr = atob(arr[1]);
      var n = bstr.length;
      var u8arr = new Uint8Array(n);

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }

      return new File([u8arr], "".concat(filename, ".").concat(suffix), {
        type: mime
      });
    },
    onSave: function onSave(res) {
      var that = this;
      that.CropperFlag = false;
      var files = that.base64toFile(res, 'filename'); //上传

      var formData = new FormData();
      formData.append('file', files);
      uploadFile(formData).then(function (res) {
        that.imagePath = store.state.staticPath + res.data;

        var obj = _objectSpread({}, store.state.resumeInfo.baseInfo);

        obj.pic_path = that.imagePath;
        that.$store.commit('saveBaseInfo', obj);
      });
    },
    onCancel: function onCancel(data) {},
    goToDetail: function goToDetail(type, index) {
      var path = '';

      if (type === '教育经历') {
        path = 'educationalexperience';
      } else if (type === '工作经历') {
        path = 'workexperience';
      } else if (type === '项目经历') {
        path = 'projectexperience';
      } else if (type === '校园经历') {
        path = 'campusexperience';
      } else if (type === '语言能力') {
        path = 'languageskills';
      } else if (type === '专业技能') {
        path = 'expertise';
      } else if (type === '个人作品') {
        path = 'individualWorks';
      } else {
        path = 'other';
      }

      this.$router.push({
        path: path,
        query: {
          index: index,
          id: this.$route.query.id,
          type: type
        }
      });
    },
    getinfo: function getinfo() {
      var _this = this;

      this.status = this.$route.query.status;
      this.template_name = this.$route.query.template_name;

      if (!store.state.resumeInfo) {
        getSingleResume(this.$route.query.id).then(function (res) {
          var resData = JSON.parse(res.data.resume_data);

          _this.$store.commit('getResumeInfo', resData);

          _this.$router.push({
            query: {
              id: _this.$route.query.id,
              status: res.data.status,
              template_name: res.data.template_name
            }
          });
        });
      }
    },
    finishResume: function finishResume() {
      var _this2 = this;

      if (this.$route.query.template_name) {
        var data = {
          id: this.$route.query.id,
          status: "4"
        };
        saveResume(data).then(function (res) {
          _this2.$router.push({
            path: 'resume_download',
            query: {
              id: _this2.$route.query.id,
              status: "4",
              template_name: _this2.$route.query.template_name
            }
          });
        });
      } else {
        _Toast.fail('请选择模板');
      }
    },
    goto: function goto(path) {
      this.$router.push({
        path: path,
        query: {
          id: this.$route.query.id,
          status: this.$route.query.status,
          template_name: this.$route.query.template_name
        }
      });
    },
    showPopup: function showPopup(i, name, type, sort) {
      this.show = true;
      this.choseElement = i;
      this.choseElementName = name;
      this.choseElementType = type;
      this.elementIndex = sort;
    },
    changeOrder: function changeOrder(data) {
      if (data.type === 'Experienced') {
        var _iterator = _createForOfIteratorHelper(store.state.resumeInfo.experienced),
            _step;

        try {
          for (_iterator.s(); !(_step = _iterator.n()).done;) {
            var item = _step.value;

            if (item.name === data.name) {
              item.index = data.index;
            }
          }
        } catch (err) {
          _iterator.e(err);
        } finally {
          _iterator.f();
        }
      } else {
        store.state.resumeInfo[data.type] = data.index;
      }
    },
    moveUp: function moveUp() {
      this.show = false;

      if (this.elementIndex === 4) {
        _Notify({
          type: 'warning',
          message: '无法上移，已经到顶了！'
        });
      } else {
        var targetIndex = this.elementIndex - 1;
        var nowChose = {
          name: this.choseElementName,
          index: targetIndex,
          type: this.choseElementType === 'Experienced' ? this.choseElementType : this.choseElementType + 'Index'
        };
        var i = store.state.resumeInfo.experienced.findIndex(function (d) {
          return d.index === targetIndex;
        });

        if (i > -1) {
          //普通经历
          var targetChose = {
            name: store.state.resumeInfo.experienced[i].name,
            index: this.elementIndex,
            type: 'Experienced'
          };
          this.changeOrder(targetChose);
        } else {
          var keys = ['languageSkillsIndex', 'individualWorksIndex', 'expertiseIndex'];

          for (var _i = 0, _keys = keys; _i < _keys.length; _i++) {
            var item = _keys[_i];

            if (store.state.resumeInfo[item] === targetIndex) {
              var _targetChose = {
                name: '',
                index: this.elementIndex,
                type: item
              };
              this.changeOrder(_targetChose);
            }
          }
        }

        this.changeOrder(nowChose);
      }
    },
    moveDown: function moveDown() {
      this.show = false;
      var count = store.state.resumeInfo.experienced.length + 2;

      if (store.state.resumeInfo.individualWorks.length > 0) {
        count = count + 1;
      }

      if (store.state.resumeInfo.expertise.length > 0) {
        count = count + 1;
      }

      if (store.state.resumeInfo.languageSkills.length > 0) {
        count = count + 1;
      }

      if (this.elementIndex === count) {
        _Notify({
          type: 'warning',
          message: '无法下移，已经到底了！'
        });
      } else {
        var targetIndex = this.elementIndex + 1;
        var nowChose = {
          name: this.choseElementName,
          index: targetIndex,
          type: this.choseElementType === 'Experienced' ? this.choseElementType : this.choseElementType + 'Index'
        };
        var i = store.state.resumeInfo.experienced.findIndex(function (d) {
          return d.index === targetIndex;
        });

        if (i > -1) {
          //普通经历
          var targetChose = {
            name: store.state.resumeInfo.experienced[i].name,
            index: this.elementIndex,
            type: 'Experienced'
          };
          this.changeOrder(targetChose);
        } else {
          var keys = ['languageSkillsIndex', 'individualWorksIndex', 'expertiseIndex'];

          for (var _i2 = 0, _keys2 = keys; _i2 < _keys2.length; _i2++) {
            var item = _keys2[_i2];

            if (store.state.resumeInfo[item] === targetIndex) {
              var _targetChose2 = {
                name: '',
                index: this.elementIndex,
                type: item
              };
              this.changeOrder(_targetChose2);
            }
          }
        }

        this.changeOrder(nowChose);
      }
    },
    deleteExperienced: function deleteExperienced() {
      var _this3 = this;

      var that = this;
      var name = ['教育经历', '工作经历'];
      var i = name.findIndex(function (d) {
        return d === _this3.choseElementName;
      });

      if (i > -1) {
        _Toast.fail("\u4E0D\u80FD\u5220\u9664".concat(this.choseElementName, "!"));

        return;
      }

      _Dialog.confirm({
        title: '提示：',
        message: '是否删除' + this.choseElementName + '?'
      }).then(function () {
        var data = {
          type: _this3.choseElementType,
          name: _this3.choseElementName
        };
        that.$store.commit('deleteExperiencedMoudle', data);

        _Toast.success('删除成功！');

        that.show = false;
      }).catch(function () {// on cancel
      });
    },
    addModule: function addModule() {
      this.$router.push({
        path: '/other',
        query: {
          flag: 'addModule'
        }
      });
    },
    goBack: function goBack() {
      this.$router.go(-1);
    },
    upDateResume: function upDateResume() {
      var _this4 = this;

      var arr = _toConsumableArray(store.state.resumeInfo.experienced);

      var baseInfo = {
        name: '基本信息',
        index: '1',
        content: _objectSpread({}, store.state.resumeInfo.baseInfo)
      };
      var introduction = {
        name: '个人简介',
        index: '2',
        content: [store.state.resumeInfo.introduction]
      };
      arr.push(baseInfo);
      arr.push(introduction);

      if (store.state.resumeInfo.expertise.length > 0) {
        var expertise = {
          name: '专业技能',
          index: store.state.resumeInfo.expertiseIndex,
          content: _toConsumableArray(store.state.resumeInfo.expertise)
        };
        arr.push(expertise);
      }

      if (store.state.resumeInfo.languageSkills.length > 0) {
        var languageSkills = {
          name: '语言能力',
          index: store.state.resumeInfo.languageSkillsIndex,
          content: _toConsumableArray(store.state.resumeInfo.languageSkills)
        };
        arr.push(languageSkills);
      }

      if (store.state.resumeInfo.individualWorks.length > 0) {
        var individualWorks = {
          name: '个人作品',
          index: store.state.resumeInfo.individualWorksIndex,
          content: _toConsumableArray(store.state.resumeInfo.individualWorks)
        };
        arr.push(individualWorks);
      }

      var data = {
        id: this.$route.query.id,
        name: this.$route.query.name,
        data: JSON.stringify(arr)
      };
      saveResume(data).then(function (res) {
        _Toast.success('保存成功！');

        _this4.$store.commit('setEditStatus', false);
      });
    }
  }
};