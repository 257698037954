import "core-js/modules/es.function.name";
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-cc22f22e"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = {
  class: "footer"
};
var _hoisted_2 = ["onClick"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.data, function (item, index) {
    return _openBlock(), _createElementBlock("div", {
      class: "footer_item",
      key: index,
      onClick: function onClick($event) {
        return $options.goto(item);
      }
    }, [_createElementVNode("div", {
      class: _normalizeClass(["footer_icon", $data.name === item.to.name ? item.icon2 : item.icon])
    }, null, 2), _createElementVNode("div", {
      class: _normalizeClass(["footer_text", $data.name === item.to.name ? "chose" : ""])
    }, _toDisplayString(item.title), 3)], 8, _hoisted_2);
  }), 128))]);
}